import { useEffect, useState, useContext } from 'react';
import { userContext } from '../providers/UserProvider';
import iconNFC from '../assets/icons/auth_nfc.svg'
import iconCheck from '../assets/icons/auth_check.svg';
import iconExpired from '../assets/icons/auth_expired.svg';
import iconNA from '../assets/icons/auth_not_allowed.svg';

export const useAuthenticationStatus = (pl) => {
  const [authStatus, setAuthStatus] = useState({
    type: 'Loading',
    message: 'Loading Authentication Status...',
    icon: iconNFC
  });

  const { user } = useContext(userContext);

  useEffect(() => {
    if (user) {
      // Check for the auth stat code
      switch (user.data.scan.auth_stat) {
        case 0:
          setAuthStatus({
            type: 'Error',
            message: 'There was a problem authenticating this tag. Please contact info@smartseal.io for more information',
            icon: iconNA
          });
          break;
        case 1:
          setAuthStatus({
            type: 'Authenticated',
            message: '',
            icon: iconCheck
          });
          break;
        case 2:
          setAuthStatus({
            type: 'Authenticated and Sealed',
            message: '',
            icon: iconCheck
          });
          break;
        case 3:
          setAuthStatus({
            type: 'Authenticated and Unsealed',
            message: '',
            icon: iconCheck
          });
          break;
        case 4:
          setAuthStatus({
            type: 'Tag Not Active',
            message: 'This tag is not currently active.',
            icon: iconNA
          });
          break;
        case 5:
          setAuthStatus({
            type: 'Tag Not Active',
            message: 'This tag is not currently active.',
            icon: iconNA
          });
          break;
        case 6:
          setAuthStatus({
            type: 'Tag Not Active',
            message: 'This tag is not currently active.',
            icon: iconNA
          });
          break;
        case 7:
          setAuthStatus({
            type: 'Authentication Token Expired',
            message: 'Please rescan tag.',
            icon: iconExpired
          });
          break;
        case 8:
          setAuthStatus({
            type: 'Authentication Code Not Valid',
            message: 'This authentication code is not valid.  Please contact sockmaster@sockhodler.com for more information.',
            icon: iconNA
          });
          break;
        default:
          setAuthStatus({
            type: 'Error',
            message: 'There was a problem authenticating this tag. Please contact sockmaster@sockhodler.com for more information.',
            icon: iconNA
          });
          break;
      }
    } else if (!pl){
      setAuthStatus({
        type: 'Please Scan Your Socks.',
        message: 'To access authentication data, please scan your NFT Socks.',
        icon: iconNFC
      })
    }
  }, [user, pl]);
  return authStatus;
};
