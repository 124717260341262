import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, MenuItem, MenuDivider, MenuList, Button, Box, FormControl, FormLabel } from '@chakra-ui/react';

const acceptedTokens = [
  { name: 'SOCKS', id: 452047208, decimals: 6 },
  { name: 'COOP', id: 796425061, decimals: 6 },
  { name: 'GALGO', id: 793124631, decimals: 6 },
  { name: 'DEFLY', id: 470842789, decimals: 6 },
];

const TokenSelector = ({ onSelect }) => {
  const [tokens, setTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState('');

  useEffect(() => {
    const fetchTokenValues = async () => {
      const fetchedTokens = await Promise.all(
        acceptedTokens.map(async token => {
          const response = await axios.get(
            `https://free-api.vestige.fi/asset/${token.id}/price?currency=USD`
          );
          return {
            ...token,
            valueInUSD: parseFloat(response.data.price),
          };
        })
      );
      setTokens(fetchedTokens);
    };

    fetchTokenValues();
  }, []);

  const handleSelect = token => {
    setSelectedToken(token.name);
    onSelect(token);
  };

  return (
    <Box mt={10}>
      <FormControl>
        <FormLabel>Select a token:</FormLabel>
        <Menu isLazy={'true'}>
          <MenuButton as={Button} w={'100%'} rightIcon={<ChevronDownIcon />}>
            {selectedToken || "Select a token"}
          </MenuButton>
          <MenuList>
            {tokens.map((token, index) => (
              <React.Fragment key={token.id}>
                <MenuItem onClick={() => handleSelect(token)}>
                  {token.name}
                </MenuItem>
                {index !== tokens.length - 1 && <MenuDivider />}
              </React.Fragment>
            ))}
          </MenuList>
        </Menu>
      </FormControl>
    </Box>
  );
};

export default TokenSelector;
