import React from 'react';
import {
  Box,
  HStack,
  IconButton,
  Text,
  Image,
  VStack,
  Heading,
  Flex,
  StackDivider,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon, CloseIcon } from '@chakra-ui/icons';

const CartItem = ({ item, onIncrease, onDecrease, onRemove }) => {
  return (
    <Box borderWidth="1px" borderRadius="xl" p={4} w="100%">
      <Flex justifyContent="space-between" alignItems="center">
        <HStack gap={2}>
          <IconButton
            size="xs"
            rounded={'md'}
            variant={'ghost'}
            icon={<CloseIcon />}
            onClick={() => onRemove(item.id)}
            aria-label="Remove Item"
          />
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={4}
          >
            <Box borderRadius={'lg'} boxSize={'75px'} overflow={'hidden'}>
              <Image src={item.imageUrl} maxH={'100%'} />
            </Box>
            <VStack alignItems="flex-start">
              <Heading as="h3" size="md">
                {item.name}
              </Heading>
              <Text>{item.description}</Text>
              <Text>
                <strong>Price: </strong>
                {item.price} ALGO
              </Text>
            </VStack>
          </Flex>
        </HStack>

        <HStack>
          <IconButton
            size="sm"
            aria-label="Decrease Quantity"
            icon={<MinusIcon />}
            onClick={() => onDecrease(item.id)}
          />
          <Text>{item.quantity}</Text>
          <IconButton
            size="sm"
            aria-label="Increase Quantity"
            icon={<AddIcon />}
            onClick={() => onIncrease(item.id)}
          />
        </HStack>
      </Flex>
    </Box>
  );
};


export default CartItem;
