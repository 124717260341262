import { createContext, useState } from 'react';
import { xpRange } from '../utils/xpRange';

export const userContext = createContext();

const UserProvider = (props) => {
  const [user, setUser] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ownsNFT, setOwnsNFT] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [xp, setXp] = useState(0);
  const [type, setType] = useState(null);
  const [scannedNFT, setScannedNFT] = useState(null);
  const [nftLevel, setNFTLevel] = useState(xpRange.get(0));
  const [badges, setBadges] = useState([]);

  return (
    <userContext.Provider value={{
      user,
      setUser,
      isAuthenticated,
      setIsAuthenticated,
      ownsNFT,
      setOwnsNFT, 
      claimed,
      setClaimed,
      xp,
      setXp,
      nftLevel,
      setNFTLevel,
      type,
      setType,
      scannedNFT,
      setScannedNFT,
      badges,
      setBadges,
    }}>
      {props.children}
    </userContext.Provider>
  )
}

export default UserProvider;