import {
    Flex,
    // Text,
    // Card,
    // CardBody,
    // CardFooter,
    // Image,
    // Stack,
    // Heading,
    // Divider,
    // ButtonGroup,
    // Button,
    // AspectRatio,
    Container,
    // Spinner,
    // Select,
  } from '@chakra-ui/react';
  import React from 'react';
  import TitleHeading from '../../components/TitleHeading/TitleHeading';
  
  const Auctions = () => {
    return (
      <Container maxW="90%">
  
        <TitleHeading
          mainTitle="Auctions"
          subTitle="Bid on your favorite NFTs"
        />
  
        <Flex
          mt="20"
          mx="4"
          align="stretch"
          justifyContent="space-around"
          flexWrap="wrap"
        >
            {/* TODO Add Cards for Auction Items */}
        </Flex>
  
      </Container>
      
    );
  };
  
  export default Auctions;
  