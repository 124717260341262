import React, { useState, useEffect, memo } from 'react';
import {
  Flex,
  Text,
  Card,
  CardBody,
  Image,
  Stack,
  Heading,
  Divider,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Link,
  SimpleGrid,
  Box,
  Spinner
} from '@chakra-ui/react';
import { reformatIpfsUrl } from '../../utils/algorand';
import { useNFTContent } from '../../hooks/useNFTContent';
import IconNFTExplorer from '../../assets/icons/icon-nft-explorer.png';
import IconAllo from '../../assets/icons/icon-allo.svg';
import IconIPFS from '../../assets/icons/icon-ipfs.png';
import { checkARCSpec, getTraitsARC69 } from '../../utils/arcSpec';
import { BLOCK_EXPLORER_ASSET_URL } from '../../config/constants';
// import { truncateLongName } from '../../utils/algorand';

const OverlayBG = () => (
  <ModalOverlay bg="rgba(27, 8, 51, 0.5)" backdropFilter="blur(6px)" />
);

const NFTCard = memo(nft => {
  const [selectedNft, setSelectedNft] = useState(null);
  const [traits, setTraits] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [arcSpec, setArcSpec] = useState(null);
  const [overlay] = React.useState(<OverlayBG />);
  const { contentComponent } = useNFTContent(nft, {});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchArcSpec = async () => {
      try {
        const spec = await checkARCSpec(nft.index);
        setArcSpec(spec);
      } catch (error) {
        console.error('Error fetching ARC spec:', error.message);
      }
    };

    setLoading(true);

    fetchArcSpec();

    if (isOpen && selectedNft) {
      getTraitsARC69(selectedNft['index'])
        .then(txn => {
          setTraits(txn.properties);
        })
        .catch(error => {
          console.error('Error:', error.message);
        });
    }

    setLoading(false);
  }, [isOpen, selectedNft, nft]);

  return (
    <>
      <Card
        maxW={'sm'}
        justify="row"
        key={nft.index}
        mb={10}
        mx="2"
        bgGradient="linear(to-b, purple.300, purple.200)"
        boxShadow="lg"
        overflow={'hidden'}
      >
        <Box
          onClick={() => {
            setSelectedNft(nft);
            onOpen();
          }}
          _hover={{ cursor: 'pointer' }}
          alt={nft.name}
          borderRadius="lg"
          maxW="100%"
          minH={'250px'}
          boxShadow="dark-lg"
        >
          {contentComponent}
        </Box>

        <CardBody p="4" textAlign="left">
          <Stack mt="6" spacing="3">
            <Heading size="md" color={'white'}>{nft.name}</Heading>

            <Stack
              direction={['column', 'row']}
              spacing="3"
              wrap={'wrap'}
              justify={'left'}
            >
              <Badge colorScheme={'green'} px={3} py={1} borderRadius={'md'}>
                {nft.index}
              </Badge>
              {/* <Badge colorScheme={'pink'} px={3} py={1} borderRadius={'md'}>
                {truncateLongName(nft.name)}
              </Badge> */}
              <Badge colorScheme={'cyan'} px={3} py={1} borderRadius={'md'}>
                {nft.total} Minted
              </Badge>
            </Stack>

            <Stack
              direction={'row'}
              spacing={'3'}
              align={'center'}
              wrap={'wrap'}
            >
              <Link
                href={`https://www.nftexplorer.app/asset/${nft.index}`}
                isExternal
              >
                <Image src={IconNFTExplorer} w="25px" />
              </Link>
              <Link
                href={`${BLOCK_EXPLORER_ASSET_URL}/asset/${nft.index}`}
                isExternal
              >
                <Image src={IconAllo} w="25px" />
              </Link>
              <Link href={reformatIpfsUrl(arcSpec ? arcSpec.url : nft.url)} isExternal>
                <Image src={IconIPFS} w="25px" />
              </Link>
              <Text color={'white'}>{arcSpec ? arcSpec.type : 'Loading...'}</Text>
            </Stack>

            {nft.cardPrice && (
              <Text color="pink.500" fontSize="2xl">
                {nft.cardPrice}
              </Text>
            )}
          </Stack>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} zIndex={10} size={'4xl'}>
        {overlay}
        <ModalContent
          borderRadius={'15px'}
          bg={'#1B0833'}
          border={'2px solid #8A2699'}
          overflow={'hidden'}
        >
          <ModalHeader p={0}>
            <Stack
              bg="#2b083d"
              borderRadius={'15px 15px 0 0'}
              padding={'1rem'}
              w={'100%'}
              align={'center'}
              justify={{
                'xs': 'center',
                'sm': 'center',
                'md': 'space-between',
                'lg': 'space-between',
                'xl': 'space-between',
                '2xl': 'space-between'
              }}
              flexDirection={{
                'xs': 'column',
                'sm': 'column',
                'md': 'row',
                'lg': 'row',
                'xl': 'row',
                '2xl': 'row'
              }}
            >
              <Heading as={'h2'} fontSize={'xl'}>
                {nft.name}
              </Heading>

              <Stack 
                direction={'row'} 
                spacing={'3'} 
                pr={{
                  'xs': '0',
                  'sm': '0',
                  'md': '50px',
                  'lg': '50px',
                  'xl': '50px',
                  '2xl': '50px'
                }}
              >
                <Link
                  href={`https://www.nftexplorer.app/asset/${nft.index}`}
                  isExternal
                >
                  <Image src={IconNFTExplorer} w="25px" />
                </Link>
                <Link
                  href={`${BLOCK_EXPLORER_ASSET_URL}/${nft.index}`}
                  isExternal
                >
                  <Image src={IconAllo} w="25px" />
                </Link>
                <Link href={reformatIpfsUrl(arcSpec ? arcSpec.url : nft.url)} isExternal>
                  <Image src={IconIPFS} w="25px" />
                </Link>
              </Stack>
            </Stack>
          </ModalHeader>
          <ModalBody p={0}>
            <Stack 
              direction={{
                'xs': 'column',
                'sm': 'column',
                'md': 'row',
                'lg': 'row',
                'xl': 'row',
                '2xl': 'row',
              }} 
              justify={{
                'xs': 'center',
                'sm': 'center',
                'md': 'center',
                'lg': 'space-between',
                'xl': 'space-between',
                '2xl': 'space-between',
              }}
              alignItems={{
                'xs': 'center',
                'sm': 'center',
                'md': 'center',
                'lg': 'flex-start',
                'xl': 'flex-start',
                '2xl': 'flex-start',
              }}
              p={10}
            >
              <Box
                maxH={'350px'}
                maxW={'350px'}
                objectFit={'contain'}
                borderRadius={'xl'}
                overflow={'hidden'}
                mb={4}
              >
                {contentComponent}
              </Box>

              <Flex flexDir={'column'}>
                <Heading as={'h3'} fontSize={'lg'}>
                  Traits
                </Heading>
                <Divider mt={2} mb={4} />

                <SimpleGrid columns={2} spacing={6}>
                {loading ? (
        <Flex justify="center" align="flex-start" w={'100%'} minW={'350px'}>
          <Spinner size="lg" />
        </Flex>
      ) : (
        traits &&
        Object.entries(traits).map(([key, value, index]) => (
          <Flex
            flexDir={'column'}
            align={'center'}
            textAlign={'center'}
            justify={'center'}
            bg={'purple.300'}
            minH={'50px'}
            minW={'75px'}
            py={'2'}
            px={'8'}
            borderRadius={'xl'}
            key={key + '-' + index}
          >
            <Heading as={'h4'} fontSize={'md'}>
              {key}
            </Heading>
            <Text>{value}</Text>
          </Flex>
        ))
      )}
                </SimpleGrid>
              </Flex>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ModalCloseButton />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default NFTCard;
