import {
  Box,
  SimpleGrid,
  Image,
  Tooltip,
  Text,
} from '@chakra-ui/react';

const AchievementBadges = ({ badges }) => {
  
  const badgeMap = badges.map((badge) => (
    <Box key={badge.badge_id}>
      <Tooltip label={badge.name} aria-label={badge.name}>
        <Image src={badge.image} borderRadius={'full'} w={'80px'} />
      </Tooltip>
    </Box>
  ));

  return (
    <>
      <SimpleGrid
        columns={{ sm: 4, md: 4, lg: 6, xl: 8, '2xl': 10 }}
        spacing={{
          sm: '10px',
          md: '10px',
          lg: '10px',
          xl: '10px',
          '2xl': '10px',
        }}
        pt={'20px'}
      >
        {badgeMap.length ? (
          badgeMap
        ) : (
          <Box w="300px">
            <Text>No achievements</Text>
          </Box>
        )}
      </SimpleGrid>
    </>
  );
};

export default AchievementBadges;
