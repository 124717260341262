import React from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Flex,
  Heading,
  Link,
  Button,
  Icon,
  VStack,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import {
  IconHome,
  // IconMarketplace,
  // IconAuctions,
  IconPortfolio,
  IconStaking,
  IconShCircle,
  IconVerified,
  IconToTheMoon,
  IconToTheDirt,
  IconBounties,
  IconSockSwap,
} from '../../utils/icons';
import { useSocksPrice } from '../../hooks/useSocksPrice';
import { useQueryParams } from '../../hooks/useQueryParams';

const Nav = () => {
  const queryParams = useQueryParams();

  const navItems = [
    {
      label: 'Home',
      to: '/',
      icon: IconHome,
      external: false,
    },
    {
      label: 'Portfolio',
      to: '/portfolio',
      icon: IconPortfolio,
      external: false,
    },
    {
      label: 'SockSwap',
      to: '/sockswap',
      icon: IconSockSwap,
      external: false,
    },
    {
      label: 'NFT Staking',
      to: 'https://staking.sockhodler.com',
      icon: IconStaking,
      external: true,
    },
    {
      label: 'SockBot Bounties',
      to: 'https://bounties.sockbotnft.com',
      icon: IconBounties,
      external: true,
    },
  ].map(item => ({
    ...item,
    to: item.external
      ? item.to
      : `${item.to}${queryParams.toString() ? '?' : ''}${queryParams.toString()}`,    
    }));

  const { socksPrice, priceChange } = useSocksPrice();

  return (
    <Flex
      textAlign="left"
      flexDirection="column"
      h="100%"
      px={{ sm: '10px', md: '15px', lg: '15px', xl: '15px', '2xl': '15px' }}
      bgGradient={'linear(to-r, purple.300, purple.200)'}
      w={{ sm: '100%', md: '15rem', lg: '20rem', xl: '20rem', '2xl': '25rem' }}
      display={{
        xs: 'none',
        sm: 'none',
        md: 'flex',
        lg: 'flex',
        xl: 'flex',
        '2xl': 'flex',
      }}
      // position="fixed"
    >
      <Heading
        as="h1"
        alignSelf={'center'}
        color="white"
        fontSize={{
          sm: '24px',
          md: '24px',
          lg: '28px',
          xl: '28px',
          '2xl': '28px',
        }}
        mt="8"
        mb="70"
        textTransform={'uppercase'}
      >
        The SockVault
      </Heading>

      <Flex direction={'column'} justify={'space-evenly'} grow={'1'}>
        {navItems.map(item => (
          <Link
            as={NavLink}
            to={item.to}
            isExternal={item.external ? true : false}
            href={item.external ? item.to : null}
            key={item.label}
            fontSize="16px"
            my={{ sm: '0px', md: '5px', lg: '10px', xl: '10px', '2xl': '15px' }}
            variant={'withBG'}
            display="flex"
            alignItems="center"
          >
            <Icon as={item.icon} boxSize={8} mr={4} />
            {item.label} {item.external && <ExternalLinkIcon mx="5px" />}
          </Link>
        ))}

        <Button mt="30px" mb="15px" variant={'disabled'}>
          Upgrade Your SockBot
        </Button>

        <Button variant={'disabled'}>Buy Packs</Button>
      </Flex>

      <Flex direction={'column'} grow={'2'} justify={'flex-end'} mb={'50'}>
        <Flex mt={10} align={'center'} justify={'stretch'}>
          <Icon as={IconShCircle} boxSize={12} mr={'4'} />

          <VStack align={'stretch'} justify={'center'}>
            <Flex>
              <Heading
                as={'h4'}
                fontSize="18px"
                mb={0}
                fontWeight={'normal'}
                textTransform={'uppercase'}
              >
                SockHodler
              </Heading>

              <Icon as={IconVerified} boxSize={6} ml={2} mr={4} mb={0} />
            </Flex>
            <Flex mt={0}>
              <Heading as={'h5'} fontSize={'16px'} mr={2}>
                SOCKS
              </Heading>
              <Heading as={'h5'} fontSize={'16px'} fontWeight={'normal'}>
                452047208
              </Heading>
            </Flex>
          </VStack>
        </Flex>

        <Flex align={'center'} justify={'stretch'} mt={4}>
          {socksPrice !== null ? (
            <>
              <Heading as={'h3'} fontSize={'22px'} mr={4} fontWeight="normal">
                ${socksPrice.USD.toFixed(5)}
              </Heading>
              <Flex align={'center'} justify="center">
                <Icon
                  as={priceChange >= 0 ? IconToTheMoon : IconToTheDirt}
                  boxSize={'13px'}
                  mr={1}
                />
                <Text
                  fontSize={'16px'}
                  color={priceChange >= 0 ? 'green' : 'red'}
                  m={0}
                >
                  {priceChange.toFixed(2)}%
                </Text>
              </Flex>
            </>
          ) : (
            <Spinner />
          )}
        </Flex>

        <Text fontSize={'12px'} mt={2}>
          Updated every 15 minutes
        </Text>
      </Flex>
    </Flex>
  );
};

export default Nav;
