import React, { useState } from 'react';
import TitleHeading from '../../components/TitleHeading/TitleHeading';
import Swapper from '../../components/Swapper/Swapper';
import TokenSelector from '../../components/TokenSelector/TokenSelector';
import { useWallet } from '@txnlab/use-wallet';
import { Flex, Text, Container, Spinner } from '@chakra-ui/react';

const SockSwap = () => {
  const { activeAccount } = useWallet();
  const [selectedToken, setSelectedToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleTokenSelect = token => {
    setLoading(true);
    setTimeout(() => {
      setSelectedToken(token);
      setLoading(false);
    }, 500);
  };

  return (
    <Container>
      <TitleHeading
        mainTitle="Sock Swap"
        subTitle="Swap your Algorand tokens for SockHodler Apparel Vouchers"
      />
  
      {activeAccount ? (
        <>
          <TokenSelector onSelect={handleTokenSelect} />
          {loading ? (
            <Flex align="center" justify="center" mt={4}>
              <Spinner size="xl" />
            </Flex>
          ) : (
            selectedToken && (
              <Swapper token={selectedToken} address={activeAccount.address} />
            )
          )}
        </>
      ) : (
        <Flex
          align={'center'}
          justify={'center'}
          flexDir={'column'}
          minH={'400px'}
        >
          <Text fontSize="xl" fontWeight="bold">
            Connect your wallet to view your NFTs
          </Text>
        </Flex>
      )}
    </Container>
  );
  
};

export default SockSwap;
