import React from 'react';
import {
  Button,
  HStack,
  VStack,
  StackDivider,
  Image,
  Heading,
  ModalBody,
  AspectRatio,
  Badge,
  Tooltip,
  Select,
} from '@chakra-ui/react';
import { useWallet } from '@txnlab/use-wallet';
import { truncateAddress } from '../../utils/algorand';

const WalletConnect = () => {
  const { providers, activeAccount } = useWallet();

  return (
    <>
      <ModalBody p={0}>
        <Heading as="h2" size="md" textTransform="uppercase" mt={4}>
          To continue using this app.
        </Heading>
        <Heading as="h3" size="sm" fontWeight="normal">
          Please connect your wallet.
        </Heading>
        <VStack
          justify="stretch"
          align="stretch"
          px={4}
          py={4}
          margin="auto"
          divider={<StackDivider borderColor="#8A2699" />}
        >
          {providers && providers?.map(provider => (
            <HStack
              key={`provider-${provider.metadata.id}`}
              justify="stretch"
              align="center"
              width="100%"
              margin="10px auto"
            >
              <VStack>
                <Tooltip
                  label={
                    provider.isActive
                      ? 'Click to Disconnect'
                      : 'Click to Connect'
                  }
                  aria-label="A tooltip"
                  hasArrow
                  arrowSize={15}
                  bgColor="#FC0174"
                  borderRadius="md"
                  color="#fff"
                  fontWeight="normal"
                >
                  <Button
                    variant="naked"
                    borderColor={provider.isActive ? '#B810FF' : '#fff'}
                    overflow="hidden"
                    onClick={
                      provider.isActive ? provider.disconnect : provider.connect
                    }
                    disabled={provider.isConnected}
                    w="8rem"
                    border={provider.isActive ? '1px solid' : 'none'}
                    padding={provider.isActive ? 2 : undefined}
                    backgroundColor={
                      provider.isActive ? 'rgba(43,8,61,.5)' : 'none'
                    }
                  >
                    <AspectRatio ratio={1}>
                      <Image
                        alt={provider.metadata.name}
                        src={provider.metadata.icon}
                        borderRadius={provider.isActive ? 'full' : undefined}
                      />
                    </AspectRatio>
                  </Button>
                </Tooltip>
                {provider.isActive && (
                  <Badge colorScheme="green" fontWeight="normal">
                    Active
                  </Badge>
                )}
              </VStack>

              <VStack width="100%" pl={8}>
                <h4>{provider.metadata.name}</h4>
                {provider.isActive && provider.accounts.length > 0 && (
                  <Select
                    value={activeAccount?.address}
                    onChange={e => provider.setActiveAccount(e.target.value)}
                  >
                    {provider.accounts.map(account => (
                      <option key={account.address} value={account.address}>
                        {truncateAddress(account.address)}
                      </option>
                    ))}
                  </Select>
                )}

                <Button
                  onClick={provider.setActiveProvider}
                  variant={
                    !provider.isConnected || provider.isActive
                      ? 'disabled'
                      : undefined
                  }
                  disabled={!provider.isConnected || provider.isActive}
                >
                  Set Active
                </Button>
              </VStack>
            </HStack>
          ))}
        </VStack>
      </ModalBody>
    </>
  );
};

export default WalletConnect;
