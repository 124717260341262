import {
  Flex,
  Text,
  Card,
  CardBody,
  CardFooter,
  Image,
  Stack,
  Heading,
  Divider,
  ButtonGroup,
  Button,
  AspectRatio,
  Spinner,
  Select,
} from '@chakra-ui/react';
import React from 'react';
import TitleHeading from '../../components/TitleHeading/TitleHeading';

const cardItems = [
  {
    cardTitle: 'Tier 1 Booster Pack',
    cardSrc:
      'https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
    cardDesc: `Tier 1 Booster Packs have a standard chance to win higher rarity SockBot Components.  3 Components included.`,
    cardPrice: `10,000 SOCKS`,
  },
  {
    cardTitle: 'Tier 2 Booster Pack',
    cardSrc:
      'https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
    cardDesc: `Tier 2 Booster Packs have a boosted chance (25%) to win higher rarity SockBot Components and include 4 NFTs instead of 3.`,
    cardPrice: `$450`,
  },
  {
    cardTitle: 'Tier 3 Booster Pack',
    cardSrc:
      'https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
    cardDesc: `This sofa is perfect for modern tropical spaces, baroque
    chic design with a sprinkle of vintage design..`,
    cardPrice: `$450`,
  },
  {
    cardTitle: 'Eagle Common',
    cardSrc:
      'https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
    cardDesc: `This sofa is perfect for modern tropical spaces, baroque
    chic design with a sprinkle of vintage design..`,
    cardPrice: `$450`,
  },
];

const Marketplace = () => {
  return (
    <Flex flexDir={'column'} mx={20}>
      <TitleHeading
        mainTitle="Marketplace"
        subTitle="Physically Backed NFT Socks"
      />

      <Flex
        mt="20"
        align="stretch"
        justifyContent="space-around"
        flexWrap="wrap"
      >
        {cardItems.map(item => {
          return (
            <Card
              maxW="sm"
              key={item.cardTitle}
              mb={10}
              mx="2"
              bgGradient="linear(to-b, purple.300, purple.200)"
              boxShadow="lg"
            >
              <AspectRatio ratio={1 / 1}>
                <Image
                  src={item.cardSrc}
                  alt={item.cardTitle}
                  borderRadius="lg"
                  maxW="100%"
                  boxShadow="dark-lg"
                  fallback={
                    <Flex
                      align={'center'}
                      justify={'center'}
                      flexDir={'column'}
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                      />
                    </Flex>
                  }
                />
              </AspectRatio>

              <CardBody p="4" textAlign="left">
                <Stack mt="6" spacing="3">
                  <Heading size="md">{item.cardTitle}</Heading>
                  <Text fontSize="lg">{item.cardDesc}</Text>
                  <Text color="pink.500" fontSize="2xl">
                    {item.cardPrice}
                  </Text>
                </Stack>
              </CardBody>
              <Divider />

              <CardFooter>
                <ButtonGroup spacing="2">
                  <Select placeholder="Pay with">
                    <option value="option1">SOCKS</option>
                    <option value="option2">ALGO</option>
                    <option value="option3">gALGO</option>
                  </Select>
                  <Button variant="solid">Buy now</Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default Marketplace;
