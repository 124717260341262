export const AuthenticationService = async (post_data) => {
    const response = {
      data: null,
      error: null,
      status: null,
    };
  
    try {
      const resp = await fetch(
        "https://socks.smartseal.io/api/authenticate/",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          mode: "cors",
          body: JSON.stringify(post_data),
        }
      );
  
      response.data = await resp.json();
      
      response.status = resp.status;
  
      return Promise.resolve(response);
    } catch (err) {
      return response.error = {
        errorMessage: err
      };
    }
  };
