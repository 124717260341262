import { useEffect, useState } from 'react';
import { createAvatar } from '@dicebear/core';
import { bottts } from '@dicebear/collection';
import { Image, Avatar } from '@chakra-ui/react';
import { AiOutlineUser } from 'react-icons/ai';
import { useWallet } from '@txnlab/use-wallet';

const BotAvatar = size => {
  const { activeAccount } = useWallet();

  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    (async () => {
      if (activeAccount) {
        const avatar = await createAvatar(bottts, {
          seed: activeAccount.address,
          options: {
            radius: 50,
            margin: 10,
            size: size,
          },
        }).toDataUri();

        setAvatar(avatar);
      } else {
        return setAvatar(null);
      }
    })();
  }, [size, activeAccount]);

  return (
    <>
      {avatar && activeAccount ? (
        <Image width={10} src={avatar} alt="Avatar" />
      ) : (
        <Avatar icon={<AiOutlineUser fontSize="1.5rem" />} />
      )}
    </>
  );
};
export default BotAvatar;
