import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import algosdk from 'algosdk';
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar';
import { Button, Text, Flex, useToast } from '@chakra-ui/react';
import { useWallet } from '@txnlab/use-wallet';
import { userContext } from '../../providers/UserProvider';
import { makeOptInAssetTxn } from '../../utils/algorand';
import { SOCKS_TOKEN } from '../../config/constants';
import { ScanService } from '../../services/ScanService';
import { updateNft, getNft } from '../../services/NFTService';
import { getUser } from '../../services/UserService';
import { achievementBadges } from '../../utils/achievementBadges';
import { MASTER_API_URL } from '../../config/constants';

const DailyScanRewards = ({ onClaim, xpValue, level, onUpdateXp }) => {
  const {
    activeAccount,
    isReady,
    signTransactions,
    sendTransactions,
    isActive,
  } = useWallet();
  const { user, isAuthenticated, claimed, badges, setBadges } = useContext(userContext);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [isClaimButtonDisabled, setIsClaimButtonDisabled] = useState(claimed);
  const [loading, setLoading] = useState(false);

  const Toast = useToast();

  ///////////////////////////////
  // Opt in to the SOCKS token //
  ///////////////////////////////

  const handleOptInTxn = async address => {
    const txn = await makeOptInAssetTxn(address, SOCKS_TOKEN);
    const encodedTxn = algosdk.encodeUnsignedTransaction(txn);
    const signedTxn = await signTransactions([encodedTxn]);
    const waitRoundsToConfirm = 4;
    const { id } = await sendTransactions(signedTxn, waitRoundsToConfirm);
    return id;
  };

  /////////////////////////////////////////////////////////////////////////
  // When the user clicks the claim button, send a request to the server //
  /////////////////////////////////////////////////////////////////////////

  const handleClaimButtonClick = async () => {
    setLoading(true);
    const currentClaimDate = new Date().toISOString();

    let badge = achievementBadges.find(badge => badge.id === currentStreak);

    if (badge && !badges.includes(badge)) {
      setBadges(prevBadges => [...prevBadges, badge]); // use the setBadges function to update the badges
    }

    const postBody = {
      user_wallet_address: activeAccount.address,
      last_claim_date: currentClaimDate,
      badges_unlocked: badge ? badge : [],
    };
    Toast({
      title: 'Claim in Process',
      description: 'Please sign the transaction in your wallet',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    const txnId = await handleOptInTxn(activeAccount.address);
    if (txnId) {
      axios
  .post(`${MASTER_API_URL}/sockvault/users`, postBody)
  .then(async (response) => {
    setIsClaimButtonDisabled(true);
    setTimeRemaining(24 * 60 * 60 * 1000); // 24 hrs in milliseconds

    let newLevel = await onUpdateXp(xpValue);

    const dailyRewardsResponse = await ScanService(
      user.data.tag.nft_token_id,
      activeAccount.address
    );

    if (dailyRewardsResponse) {
      const dailyScanXP = dailyRewardsResponse.accrued_scan_rewards;

      if (newLevel) {
        console.log('newLevel', newLevel);
  
        await updateNft(user.data.tag.nft_token_id, xpValue, newLevel.level);
      }

      Toast({
        title: 'Claim Successful',
        description: `You have claimed ${dailyScanXP} SOCKS`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }

    onClaim();
    setLoading(false);
  })
  .catch(error => {
    console.error(error);
    setIsClaimButtonDisabled(true);
    Toast({
      title: 'Error',
      description: 'Check back in 24 hours',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    setTimeRemaining(24 * 60 * 60 * 1000); // 24 hrs in milliseconds
  });

    }

  };

  // Get the user's current scan streak
  useEffect(() => {
    if (isReady && activeAccount.address) {
      const getCurrentStreak = async () => {
        const { data } = await getUser(activeAccount.address);
        setCurrentStreak(data.current_scan_streak);
      };

      getCurrentStreak();
    }
  }, [isReady, isActive, activeAccount]);

  // Update the timer every second
  useEffect(() => {
    if (isReady) {
      const getTimeRemaining = async () => {
        try {
          const { data } = await getNft(user.data.tag.nft_token_id);
          const lastClaimDate = new Date(data.last_claim_date);
          const timeRemaining =
            24 * 60 * 60 * 1000 - (Date.now() - lastClaimDate);

          setTimeRemaining(timeRemaining);
        } catch (error) {
          console.error(error);
        }
      };

      getTimeRemaining();
      const intervalId = setInterval(() => {
        setTimeRemaining(timeRemaining => timeRemaining - 1000);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isReady, isActive, user.data.tag.nft_token_id]);

  useEffect(() => {
    // When the timer reaches 0, enable the claim button
    if (timeRemaining <= 0) {
      setIsClaimButtonDisabled(false);
      setTimeRemaining(null);
    }

    if (timeRemaining > 0 || !isAuthenticated || !activeAccount) {
      setIsClaimButtonDisabled(true);
    }
  }, [timeRemaining, activeAccount, isAuthenticated]);

  // Convert the time remaining to hours, minutes, and seconds
  const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);

  return (
    <>
      <Flex
        width={'100%'}
        maxW={'400px'}
        justify={'stretch'}
        alignItems={'stretch'}
        flexDir={'column'}
        mb={'30px'}
      >
        <CircularProgressBar value={currentStreak} />
        <Flex
          flexDir={'column'}
          justify={'center'}
          mt="30px"
          textAlign={'center'}
        >
          <Button
            isDisabled={isClaimButtonDisabled}
            onClick={handleClaimButtonClick}
            isLoading={loading}
            loadingText="Claiming"
            spinnerPlacement="end"
          >
            Claim
          </Button>

          {timeRemaining > 0 ? (
            <>
              <Text mt={'15px'} color="white">
                Time remaining: <br />
              </Text>

              <Text color="#9F67C8">
                {hours} Hrs, {minutes} Mins, {seconds} secs
              </Text>
            </>
          ) : (
            <Text>Ready, Freddy.</Text>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default DailyScanRewards;
