import axios from "axios";
import { MASTER_API_URL } from "../config/constants";

const BASE_URL = `${MASTER_API_URL}/sockvault`;

export const updateNft = async (asa_id, xp, level) => {
  return axios.post(`${BASE_URL}/updatenfts`, {
    asa_id,
    xp,
    level
  })
    .then(() => {
      Promise.resolve()
    })
    .catch(err => {
      throw new Error(err);
    });
}

export const addNFT = async (user_wallet_address, asa_id) => {
  return axios.post(`${BASE_URL}/addnfts`, {
    user_wallet_address,
    asa_id
  })
    .then(() => {
      Promise.resolve()
    })
    .catch(err => {
      throw new Error(err);
    });
}

export const getNft = async (asa_id) => {
  return axios.get(`${BASE_URL}/getnfts/${asa_id}`);
}
