import axios from 'axios';
import { makeOptInAssetTxn, checkNFTOwnership } from '../utils/algorand';
import { SOCKBOT_V2_COLLECTION_ADDRESS } from '../config/constants';

export const RedeemService = async (address, asaId) => {
    // Check if the user holds a balance of this ASA
    const { 
        ownsNFT: userOwnsNFT, 
        error: userError } = await checkNFTOwnership(address, asaId);
    if (userError) {
      console.error('Error checking NFT ownership for user:', userError);
      throw userError;
    }

    console.log("SockBot Address:", SOCKBOT_V2_COLLECTION_ADDRESS)
    console.log("ASA ID:", asaId)
    
    // Check if the platform wallet holds the NFT
    const { 
        ownsNFT: platformOwnsNFT, 
        error: platformError 
    } = await checkNFTOwnership(SOCKBOT_V2_COLLECTION_ADDRESS, asaId);

    console.log("User owns NFT: ", userOwnsNFT);
    console.log("Platform owns NFT: ", platformOwnsNFT);
    
    if (platformError) {
      console.error('Error checking NFT ownership for platform:', platformError);
      throw platformError;
    }

    if (!userOwnsNFT && platformOwnsNFT) {

        console.log("User does not own NFT, but platform does");
        console.log('ASAID:', asaId)
        // If user does not own the NFT and platform does own the NFT, then opt-in to the ASA
        try {
            const txn = makeOptInAssetTxn(address, Number(asaId));
            if(txn) {
                return txn;
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    } else {
        // If yes, then return false
        return false;
    };
}
