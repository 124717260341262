import React, { useState } from 'react';
import {
  VStack,
  Box,
  Button,
  Text,
  useToast,
  Heading,
  HStack,
  Flex,
} from '@chakra-ui/react';
import CartItem from '../CartItem/CartItem';
import CheckoutConfirmation from '../CheckoutConfirmation/CheckoutConfirmation';
import { SOCKBOT_COLLECTION_ADDRESS } from '../../config/constants';
import { useWallet } from '@txnlab/use-wallet';
import { makeGroupedPurchaseTxn } from '../../utils/algorand';

const ShoppingCart = () => {
  const { activeAccount } = useWallet();
  const Toast = useToast();
  const [checkoutComplete, setCheckoutComplete] = useState(false);
  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      asaId: 0,
      name: 'Changepurse of Algo - 10',
      description: 'Description for NFT Item 1',
      price: 10,
      imageUrl: 'https://via.placeholder.com/150',
      quantity: 1,
    },
    {
      id: 2,
      asaId: 0,
      name: 'Satchel of Algo - 20',
      description: 'Description for NFT Item 2',
      price: 20,
      imageUrl: 'https://via.placeholder.com/150',
      quantity: 1,
    },
    {
      id: 3,
      asaId: 0,
      name: 'Bag of Algo - 30',
      description: 'Description for NFT Item 3',
      price: 30,
      imageUrl: 'https://via.placeholder.com/150',
      quantity: 1,
    },
  ]);

  const increaseQuantity = itemId => {
    setCartItems(
      cartItems.map(item =>
        item.id === itemId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const decreaseQuantity = itemId => {
    setCartItems(
      cartItems.map(item =>
        item.id === itemId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const removeItem = itemId => {
    setCartItems(cartItems.filter(item => item.id !== itemId));
  };

  const handleCheckout = async () => {
    
    // Check what ASA they are paying with.
    const getPaymentMethod = () => {
    
    };

    // 
    const checkoutCartItems = cartItems.map(item => ({
      asa_id: item.asaId,
      from: activeAccount.address,
      to: SOCKBOT_COLLECTION_ADDRESS,
      amount: item.price * item.quantity,
    }));

    // makeGroupedPurchaseTxn(checkoutCartItems);

    console.log('checkoutItems', checkoutCartItems);

    //   toast({
    //     title: 'Checkout successful.',
    //     description: 'Your NFTs have been purchased.',
    //     status: 'success',
    //     duration: 3000,
    //     isClosable: true,
    //   });

    // Clear the cart items
    //   setCartItems([]);

    //   Toast({
    //     title: 'Checkout failed.',
    //     description: 'An error occurred during the checkout process.',
    //     status: 'error',
    //     duration: 3000,
    //     isClosable: true,
    //   });
    // }
  };

  const total = cartItems.reduce(
    (accumulator, item) => accumulator + item.price * item.quantity,
    0
  );

  const totalItems = cartItems.reduce(
    (accumulator, item) => accumulator + item.quantity,
    0
  );

  return (
    <Box>
      {checkoutComplete ? (
        <CheckoutConfirmation cartItems={cartItems} total={total} />
      ) : (
        <Flex flexDir={'column'}>
          <VStack mb={'4'}>
            {cartItems.map(item => (
              <CartItem
                key={item.id}
                item={item}
                onIncrease={increaseQuantity}
                onDecrease={decreaseQuantity}
                onRemove={removeItem}
              />
            ))}
          </VStack>

          <HStack gap={4} align={'center'} justify={'center'}>
            <Box>
              <Heading
                as={'h4'}
                fontSize="lg"
                mt={4}
                mb={1}
                textAlign={'center'}
              >
                Total Items
              </Heading>
              <Text fontSize="md" mb={8} textAlign={'center'}>
                {totalItems}
              </Text>
            </Box>
            <Box>
              <Heading
                as={'h4'}
                fontSize="lg"
                mt={4}
                mb={1}
                textAlign={'center'}
              >
                Total Cost
              </Heading>
              <Text fontSize="md" mb={8} textAlign={'center'}>
                {total.toFixed(2)}
              </Text>
            </Box>
          </HStack>

          <Button onClick={handleCheckout} variant={'solidPurple'} py={8}>
            Checkout
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default ShoppingCart;
