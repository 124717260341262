import axios from 'axios';
import { MASTER_API_URL } from '../config/constants';
import { incrementXP } from './UserService';
import { generateTransactions } from '../utils/missionUtils';

export const claimAllRewards = async (
  asa_id, 
  user_wallet_address, 
  mission_id, 
  rewards, 
  level
) => {
  try {
    const xpReward = rewards.find(reward => reward.type === 'XP');
    if (xpReward) {
      await incrementXP(asa_id, xpReward.value, level);
    }

    const nonXPAssets = rewards.filter(reward => reward.type !== 'XP');
    await Promise.all(generateTransactions(user_wallet_address, nonXPAssets));

    const response = await axios.post(`${MASTER_API_URL}/sockvault/missions/claimrewards`, {
      asa_id,
      user_wallet_address,
      mission_id,
      rewards,
      level
    });

    console.log('Rewards Claimed', response.data);
    return response.data;
    
  } catch (err) {
    console.error(err);
  }
};

// export const claimReward = async (
//   asa_id,
//   user_wallet_address,
//   mission_id,
//   rewards,
//   level
// ) => {
//   try {
//     if (rewards && rewards.find(reward => reward.includes('XP'))) {
//       console.log('level', level);
//       const xp = rewards.find(reward => reward.includes('XP')).split('XP')[0];
//       const res = await axios.post(`${MASTER_API_URL}/sockvault/users/xp`, {
//         asa_id,
//         xp,
//         level
//       });
//       console.log('XP Claimed', res);
//     }

//     if (rewards && rewards.find(reward => reward.includes('SOCKS Tokens'))) {
//       const asa_amount = rewards
//         .find(reward => reward.includes(' SOCKS Tokens'))
//         .split(' SOCKS Tokens')[0];

//       console.log('asa_amount', asa_amount);
//       // Send to Fulfill Missions Route
//       const res = await axios.post(
//         `${MASTER_API_URL}/sockvault/missions/claim/${asa_id}`,
//         {
//           asa_id,
//           user_wallet_address,
//           mission_id,
//           asa_amount,
//         }
//       );    

//       console.log('Mission Fulfilled', res);
//     }
//   } catch (err) {
//     console.error(err);
//   }
// };

// Get all missions from the backend
export const getMissions = async () => {
  return axios
    .get(`${MASTER_API_URL}/sockvault/missions`)
    .then(res => {
      return res.data.missions;
    })
    .catch(err => {
      throw new Error(err?.message);
    });
};

// Get mission statuses from the backend
export const getMissionProgress = async asa_id => {
  return axios
    .get(`${MASTER_API_URL}/sockvault/missions/progress/${asa_id}`)
    .then(res => {
      return res.data.mission_progress;
    })
    .catch(err => {
      throw new Error(err?.message);
    });
};

// Send the mission_id, status, and asa_id to the backend and update the mission assigned to the specific NFT
export const updateMission = async (asa_id, mission_id, status) => {
  try {
    const res = await axios.post(
      `${MASTER_API_URL}/sockvault/missions/${asa_id}`,
      {
        asa_id,
        mission_id,
        status,
      }
    );
    console.log(res);
    if (res.data.message !== 'Update successful') {
      throw new Error('Update unsuccessful');
    }
  } catch (error) {
    console.error('Failed to update mission:', error);
  }
};
