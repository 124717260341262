import Algosdk from 'algosdk';
import axios from 'axios';
import {
  ALGOD_PORT,
  ALGOD_SERVER,
  INDEXER_SERVER,
  ALGOD_TOKEN,
  ALGO_TOKEN,
  GALGO_TOKEN,
  SOCKS_TOKEN,
} from '../config/constants';
import algosdk from 'algosdk';

// Instantiate the algod wrapper
export const algodClient = new Algosdk.Algodv2(ALGOD_TOKEN, ALGOD_SERVER, ALGOD_PORT);
export const indexerClient = new Algosdk.Indexer(
  ALGOD_TOKEN,
  INDEXER_SERVER,
  ALGOD_PORT
);

export const checkNFTOwnership = async (address, asaId) => {
  try {
    // Convert asaId to a number
    const asaIdNumber = Number(asaId);

    // Fetch account information
    let accountInfo = await algodClient.accountInformation(address).do();
    
    // Check if the account has the NFT
    for (let asset of accountInfo.assets) {
      if (asset['asset-id'] === asaIdNumber && asset['amount'] > 0) {

        // The account has the NFT
        return { ownsNFT: true };
      }
    }

    // The account does not have the NFT
    return { ownsNFT: false };
  } catch (error) {
    return { error };
  }
}; 


export const getAlgoBalance = async address => {
  try {
    const accountInfo = await algodClient.accountInformation(address).do();
    return accountInfo.amount;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const getTokenPrice = async asaID => {
  try {
    let response;
    if (asaID === ALGO_TOKEN) {
      response = await axios.get('https://free-api.vestige.fi/currency/prices');
      const data = response.data;
      return data['USD'];
    } else {
      response = await axios.get(
        `https://free-api.vestige.fi/asset/${asaID}/price`
      );
      const data = response.data;
      // console.log(`Token Price for ${asaID}`, data['USD']);
      return data['USD'];
    }
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getCreatorAssets = async address => {
  try {
    let assets = [];
    let nextToken = undefined;
    do {
      const response = await indexerClient
        .searchForAssets()
        .creator(address)
        .limit(1000)
        .includeAll(false)
        .nextToken(nextToken)
        .do();
      assets = assets.concat(response.assets);
      nextToken = response['next-token'];
    } while (nextToken);
    return assets;
  } catch (error) {
    console.error(error);
  }
};

export const getAccountAssets = async address => {
  try {
    let assets = [];
    let nextToken = undefined;
    do {
      const response = await indexerClient
        .lookupAccountAssets(address)
        .limit(1000)
        .includeAll(false)
        .nextToken(nextToken)
        .do();
      assets = assets.concat(response.assets);
      nextToken = response['next-token'];
    } while (nextToken);
    return assets;
  } catch (error) {
    console.error(error);
  }
};

export const getAccountTokens = async (address, tokenId) => {
  try {
    if (tokenId === ALGO_TOKEN) {
      const algoBalance = await getAlgoBalance(address);
      return algoBalance;
    } else {
      const asset = await indexerClient
        .lookupAccountAssets(address)
        .assetId(tokenId)
        .do();
      if (asset['assets'] && asset['assets'].length > 0) {
        const amount = asset['assets'][0]['amount'];
        return amount;
      } else {
        return 0;
      }
    }
  } catch (error) {
    console.error(
      `Error fetching token balance for tokenId ${tokenId} and address ${address}:`,
      error
    );
    return 0;
  }
};

export const getOwnedAssets = async (creatorAddress, userAddress) => {
  const creatorAssets = await getCreatorAssets(creatorAddress);
  const accountAssets = await getAccountAssets(userAddress);
  const ownedAssets = creatorAssets.filter(creatorAsset => {
    return accountAssets.some(accountAsset => {
      return accountAsset['asset-id'] === creatorAsset['index'];
    });
  });
  return ownedAssets;
};

export const getTokenName = assetId => {
  if (assetId === ALGO_TOKEN) {
    return 'Algo';
  } else if (assetId === GALGO_TOKEN) {
    return 'gAlgo';
  } else if (assetId === SOCKS_TOKEN) {
    return 'SOCKS';
  }
};

export const extractCidFromUrl = url => {
  const ipfsRegex = /\/ipfs\/([^/]+)/;
  const normalRegex = /\/([a-zA-Z0-9]+)$/;

  const ipfsMatch = url.match(ipfsRegex);
  const normalMatch = url.match(normalRegex);

  if (ipfsMatch) {
    return ipfsMatch[1];
  } else if (normalMatch) {
    return normalMatch[1];
  } else {
    return null;
  }
};

export const reformatIpfsUrl = url => {
  // If the media doesn't have "https://sockhodler.mypinata.cloud/ipfs/" in it, replace it with "https://ipfs.algonft.tools/ipfs/"
  if (/ipfs:\/\//.test(url)) {
    url = url.replace('ipfs://', 'https://ipfs.algonft.tools/ipfs/');
  }
  return url;
};

export const reformatDecimals = (amount, decimals) => {
  const amountWithDecimals = (amount / Math.pow(10, decimals)).toFixed(2);
  return Number(amountWithDecimals).toLocaleString('en-US');
};

export const truncateAddress = address => {
  const truncatedAddress = `${address.slice(0, 5)}...${address.slice(
    address.length - 5,
    address.length
  )}`;
  return truncatedAddress;
};

export const truncateLongName = name => {
  const truncatedName = `${name.slice(0, 10)}...${name.slice(
    name.length - 10,
    name.length
  )}`;
  return truncatedName;
};

// Build the Opt-In Asset Transaction
export const makeOptInAssetTxn = async (from, assetId) => {
  const params = await algodClient.getTransactionParams().do();
  const optInTxn = Algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: from,
    to: from,
    amount: 0,
    assetIndex: assetId,
    suggestedParams: params,
  });
  return optInTxn;
};

export const makePaymentTxn = async (from, to, amount, note, assetId) => {
  const params = await algodClient.getTransactionParams().do();
  const paymentTxn = Algosdk.makePaymentTxnWithSuggestedParams(
    from,
    to,
    amount,
    note,
    params
  );
  return paymentTxn;
};

// Build the Transfer Asset Transaction
export const makeXferAssetTxn = async (from, to, amount, note, assetId) => {
  const params = await algodClient.getTransactionParams().do();
  const xferTxn = Algosdk.makeAssetTransferTxnWithSuggestedParams(
    from,
    to,
    amount,
    note,
    assetId,
    params
  );
  return xferTxn;
};

export const makeGroupedOptInTxn = (receiverAddress, assetIds) => {
  const transactions = assetIds.map(assetId => 
    makeOptInAssetTxn(receiverAddress, assetId)
  );
  algosdk.assignGroupID(transactions);
  return transactions;
}

export const makeGroupedPurchaseTxn = async checkoutItems => {
  // Need to make sure that the multiple items are coming over
  try {
    const transactions = [];

    for (const item of checkoutItems) {
      if (item.asa_id !== ALGO_TOKEN) {
        const userBalance = await getAccountTokens(item.from, item.asa_id);
        if (userBalance < item.amount) {
          throw new Error(`You do not have enough to send.`);
        }
        const txn = makeXferAssetTxn(
          item.from, // from
          item.to, // to
          item.amount, // amount
          item.note, // note
          item.assetId, // assetIndex
        );
        transactions.push(txn);
      }

      if (item.asa_id === ALGO_TOKEN) {
        const userBalance = await getAlgoBalance(item.from);
        if (userBalance < item.amount) {
          throw new Error(`You do not have enough to send.`);
        }

        const txn = Algosdk.makePaymentTxnWithSuggestedParams(
          item.from, // from
          item.to, // to
          item.amount, // amount
          item.note, // note
          item.assetId, // assetIndex
        );
        transactions.push(txn);
      }

    }

      // Group the transactions via the transactions variable
      const groupID = Algosdk.computeGroupID(transactions);
      transactions.forEach(txn => {
        txn.group = groupID;
      });

      // Return the txn group
      return transactions;

  } catch (error) {
    console.error('Error creating transaction group:', error);
  }
};

export const sendTxnToBackend = async (txn, from) => {
  const response = await axios.post('/send-transaction', {
    // TODO - Fill out the route
    xferTxn: txn,
    from,
  });
  return response;
};
