import React, { useContext, useEffect, useState, useMemo } from 'react';
import Dashboard from '../../components/Dashboard/Dashboard';
import Authenticate from '../../components/Authenticate/Authenticate';
import { Flex, Button } from '@chakra-ui/react';
import Tabs from '../../components/Tabs/Tabs';
import NFT from '../../components/NFT/NFT';
import './Home.css';
import { userContext } from '../../providers/UserProvider';
import { useWallet } from '@txnlab/use-wallet';
import { checkNFTOwnership } from '../../utils/algorand.js';

const Home = () => {
  const { user, isAuthenticated, setOwnsNFT, ownsNFT } = useContext(userContext);
  const { isActive, activeAccount } = useWallet();
  const [showDashboard, setShowDashboard] = useState(false);

  const tabButtons = useMemo(() => [
    {
      label: 'Authenticate',
      onClick: () => setShowDashboard(false),
      active: !showDashboard,
    },
    {
      label: 'Dashboard',
      onClick: () => (isAuthenticated ? setShowDashboard(true) : null),
      active: showDashboard,
      disabled: !isAuthenticated || !ownsNFT,
    },
  ], [showDashboard, isAuthenticated, ownsNFT]);

  const [selectedTab, setSelectedTab] = useState(tabButtons[0].label); // initialize selectedTab to the first tabButton label

  useEffect(() => {
    if (isAuthenticated && user && isActive) {
      checkNFTOwnership(activeAccount.address, user.data.tag.nft_token_id)
        .then(({ ownsNFT, error }) => {
          if (error) {
            console.error('Error checking NFT ownership:', error);
          } else {
            setOwnsNFT(ownsNFT);
          }
        })
    }
  }, [isAuthenticated, user, setOwnsNFT, activeAccount, isActive]);

  return (
    <Flex flexDir={'column'} mt={4}>
      <Tabs>
        {tabButtons.map(tabButton => (
          <Button
            key={tabButton.label}
            variant={tabButton.disabled ? 'disabled' : null}
            borderRadius="full"
            bgGradient="linear(to-l, purple.300, purple.200)"
            onClick={() => {
              setSelectedTab(tabButton.label);
              tabButton.onClick();
            }}
            className={selectedTab === tabButton.label ? 'active' : ''}
            _hover={{
              bgGradient:
                'linear(45deg, rgb(17, 0, 36) 0%, rgb(82, 9, 121) 0%, rgb(180, 70, 244) 100%)',
            }}
            isDisabled={tabButton.disabled}
            cursor={tabButton.disabled ? 'not-allowed' : 'pointer'}
            css={{
              '&.active': {
                background:
                  'linear-gradient(45deg, rgb(17, 0, 36) 0%, rgb(82, 9, 121) 0%, rgb(180, 70, 244) 100%)',
              },
            }}
          >
            {tabButton.label}
          </Button>
        ))}
      </Tabs>

      {showDashboard && user && user.data && user.data.tag ? (
        <Flex flexDir={'column'} mx={{
          base: 10,
          lg: 20,
        }}
        >
          <Dashboard
            asaName={user.data.tag.algo_assetname}
            url={user.data.tag.algo_url}
            reserveAddress={user.data.tag.algo_reserveaddr}
            asaId={user.data.tag.nft_token_id}
          />
        </Flex>
      ) : (
        <Flex flexDir={'column'} mx={20}>
          <Authenticate />
        </Flex>
      )}

      {isAuthenticated && !showDashboard && user && user.data && user.data.tag && (
        <Flex flexDir={'column'} mx={20}>
          <NFT
            uid={user.data.tag.uid}
            asaName={user.data.tag.algo_assetname}
            image={user.data.tag.algo_url}
            asaId={user.data.tag.nft_token_id}
            ownerAddress={user.data.tag.algo_owner}
            creatorAddress={user.data.tag.algo_creator}
            totalSupply={user.data.tag.algo_total}
            reserveAddress={user.data.tag.algo_reserveaddr}
            url={user.data.tag.algo_url}
          />
        </Flex>
      )}
    </Flex>
  );
};
export default Home;
