import { mode } from '@chakra-ui/theme-tools';

const styles = {
  global: props => ({
    body: {
      height: '100%',
      width: '100%',
      backgroundAttachment: 'fixed',
      margin: 0,
      fontFamily: 'inter',
      color: 'white',
      bgGradient: 'linear(to-b, purple.200, purple.300)',
      lineHeight: 'base',
    },
    a: {
      color: mode('blue.300', 'blue.900')(props),
      _hover: {
        textDecoration: 'none',
        color: 'pink.500',
      }
    },
})
};

export default styles;
