import { useState, useEffect, useCallback } from 'react';
import { IPFS_GATEWAY } from '../config/constants';
import { checkGatewaySpec } from '../utils/arcSpec';

const replaceIpfsPrefix = (url, prefix) => {
  if (url.startsWith('ipfs://')) {
    return prefix + url.slice(7);
  }
  return url;
};

export const useNFTContent = (nft, additionalProps = {}) => {
  const [content, setContent] = useState(null);
  const [mediaType, setMediaType] = useState('image');

  const handleNFTContent = useCallback(async () => {
    try {
      const url = checkGatewaySpec(nft.url, nft.reserveAddr);
      let httpsUrl = replaceIpfsPrefix(url, IPFS_GATEWAY);

      const response = await fetch(httpsUrl, { method: 'HEAD' });
      const contentType = response.headers.get('content-type');

      if (contentType.includes('video')) {
        setMediaType('video');
      } else if (contentType.includes('image')) {
        setMediaType('image');
      } else if (contentType.includes('json')) {
        setMediaType('json');

        const jsonResponse = await fetch(httpsUrl);
        const jsonData = await jsonResponse.json();
        const image = jsonData['image'];
        httpsUrl = replaceIpfsPrefix(image, IPFS_GATEWAY);
        setContent(httpsUrl);
        return;
      }

      setContent(httpsUrl);
    } catch (error) {
      console.log(error);
    }
  }, [nft]);

  useEffect(() => {
    handleNFTContent();
  }, [handleNFTContent]);

  const contentComponent = (() => {
    switch (mediaType) {
      case 'video':
        return (
          <video
            src={content}
            controls
            autoPlay
            loop
            {...additionalProps}
          />
        );
      case 'image':
        return (
          <img
            src={content}
            alt="NFT"
            {...additionalProps}
          />
        );
      case 'json':
        return (
          <img
            src={content}
            alt="NFT"
            {...additionalProps}
          />
        );
      default:
        return (
          <img
            src="https://placehold.it/500x500"
            alt="NFT"
            {...additionalProps}
          />
        );
    }
  })();

  return {
    content,
    contentComponent,
  };
};
