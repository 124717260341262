// Peacemaker
// The Reluctant Hero
// Guardian of Order
// Battleborn
// Full Metal Soldier
// Steel Man of Vengeance
// Weapon of War
// Unleashed
// The Ultimate Warrior
// SockBot Prime

export const xpRange = new Map([
  [1, { minXp: 0, maxXp: 300, levelName: 'Peacemaker', level: 1 }],
  [2, { minXp: 300, maxXp: 600, levelName: 'Peacemaker', level: 2 }],
  [3, { minXp: 600, maxXp: 900, levelName: 'Peacemaker', level: 3 }],
  [4, { minXp: 900, maxXp: 1200, levelName: 'Peacemaker', level: 4 }],
  [5, { minXp: 1200, maxXp: 1500, levelName: 'Peacemaker', level: 5 }],
  [6, { minXp: 1500, maxXp: 1800, levelName: 'The Reluctant Hero', level: 6 }],
  [7, { minXp: 1800, maxXp: 2400, levelName: 'The Reluctant Hero', level: 7 }],
  [8, { minXp: 2400, maxXp: 3100, levelName: 'The Reluctant Hero', level: 8 }],
  [9, { minXp: 3100, maxXp: 3900, levelName: 'The Reluctant Hero', level: 9 }],
  [10, { minXp: 3900, maxXp: 4800, levelName: 'The Reluctant Hero', level: 10, multiplier: 2 }],
  [11, { minXp: 4800, maxXp: 6300, levelName: 'Guardian of Order', level: 11 }],
  [12, { minXp: 6300, maxXp: 9450, levelName: 'Guardian of Order', level: 12 }],
  [13, { minXp: 9450, maxXp: 12600, levelName: 'Guardian of Order', level: 13 }],
  [14, { minXp: 12600, maxXp: 15187, levelName: 'Guardian of Order', level: 14 }],
  [15, { minXp: 15187, maxXp: 22780, levelName: 'Guardian of Order', level: 15 }],
  [16, { minXp: 22780, maxXp: 34170, levelName: 'Battleborn', level: 16 }],
  [17, { minXp: 34170, maxXp: 51255, levelName: 'Battleborn', level: 17 }],
  [18, { minXp: 51255, maxXp: 76882, levelName: 'Battleborn', level: 18 }],
  [19, { minXp: 76882, maxXp: 115323, levelName: 'Battleborn', level: 19 }],
  [20, { minXp: 115323, maxXp: 172984, levelName: 'Battleborn', level: 20 }],
  [21, { minXp: 172984, maxXp: 259476, levelName: 'Full Metal Soldier', level: 21 }],
  [22, { minXp: 259476, maxXp: 389214, levelName: 'Full Metal Soldier', level: 22 }],
  [23, { minXp: 389214, maxXp: 583821, levelName: 'Full Metal Soldier', level: 23 }],
  [24, { minXp: 583821, maxXp: 875731, levelName: 'Full Metal Soldier', level: 24 }],
  [25, { minXp: 875731, maxXp: 1313596, levelName: 'Full Metal Soldier', level: 25, multiplier: 4 }],
  [26, { minXp: 1313596, maxXp: 1970394, levelName: 'Steel Man of Vengeance', level: 26 }],
  [27, { minXp: 1970394, maxXp: 2955591, levelName: 'Steel Man of Vengeance', level: 27 }],
  [28, { minXp: 2955591, maxXp: 4433386, levelName: 'Steel Man of Vengeance', level: 28 }],
  [29, { minXp: 4433386, maxXp: 6650079, levelName: 'Steel Man of Vengeance', level: 29 }],
  [30, { minXp: 6650079, maxXp: 9975118, levelName: 'Steel Man of Vengeance', level: 30 }],
  [31, { minXp: 9975118, maxXp: 14962677, levelName: 'Weapon of War', level: 31 }],
  [32, { minXp: 14962677, maxXp: 22444015, levelName: 'Weapon of War', level: 32 }],
  [33, { minXp: 22444015, maxXp: 33666022, levelName: 'Weapon of War', level: 33 }],
  [34, { minXp: 33666022, maxXp: 50499033, levelName: 'Weapon of War', level: 34 }],
  [35, { minXp: 50499033, maxXp: 75748549, levelName: 'Weapon of War', level: 35 }],
  [36, { minXp: 75748549, maxXp: 113622823, levelName: 'Unleashed', level: 36 }],
  [37, { minXp: 113622823, maxXp: 170434234, levelName: 'Unleashed', level: 37 }],
  [38, { minXp: 170434234, maxXp: 255651350, levelName: 'Unleashed', level: 38 }],
  [39, { minXp: 255651350, maxXp: 383527025, levelName: 'Unleashed', level: 39 }],
  [40, { minXp: 383527025, maxXp: 575290537, levelName: 'Unleashed', level: 40, multiplier: 5 }],
  [41, { minXp: 575290537, maxXp: 862935805, levelName: 'The Ultimate Warrior', level: 41 }],
  [42, { minXp: 862935805, maxXp: 1294403707, levelName: 'The Ultimate Warrior', level: 42 }],
  [43, { minXp: 1294403707, maxXp: 1941605560, levelName: 'The Ultimate Warrior', level: 43 }],
  [44, { minXp: 1941605560, maxXp: 2912408340, levelName: 'The Ultimate Warrior', level: 44 }],
  [45, { minXp: 2912408340, maxXp: 4368612510, levelName: 'The Ultimate Warrior', level: 45 }],
  [46, { minXp: 4368612510, maxXp: 6552918765, levelName: 'SockBot Prime', level: 46 }],
  [47, { minXp: 6552918765, maxXp: 9829378147, levelName: 'SockBot Prime', level: 47 }],
  [48, { minXp: 9829378147, maxXp: 14744067220, levelName: 'SockBot Prime', level: 48 }],
  [49, { minXp: 14744067220, maxXp: 22116100830, levelName: 'SockBot Prime', level: 49 }],
  [50, { minXp: 22116100830, maxXp: 33174151245, levelName: 'SockBot Prime', level: 50, multiplier: 10 }],
]);