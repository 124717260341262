export const SOCKS_TOKEN = parseInt(process.env.REACT_APP_SOCKS_TOKEN);
export const SOCKS_TOKEN_DECIMALS = 6;

export const ALGO_TOKEN = parseInt(process.env.REACT_APP_ALGO_TOKEN);
export const ALGO_TOKEN_DECIMALS = 6;

export const GALGO_TOKEN = parseInt(process.env.REACT_APP_GALGO_TOKEN);
export const GALGO_TOKEN_DECIMALS = 6;

export const COOP_TOKEN = parseInt(process.env.REACT_APP_COOP_TOKEN);
export const COOP_TOKEN_DECIMALS = 6;

export const PLATFORM_WALLET = process.env.REACT_APP_PLATFORM_WALLET;

export const ALGOD_SERVER = process.env.REACT_APP_ALGOD_SERVER;
export const INDEXER_SERVER = process.env.REACT_APP_INDEXER_SERVER;

export const ALGOD_PORT = process.env.REACT_APP_ALGOD_PORT;
export const ALGOD_TOKEN = process.env.REACT_APP_ALGOD_TOKEN;
export const PURESTAKE_API_KEY = process.env.REACT_APP_PURESTAKE_API_KEY;


export const GENESIS_COLLECTION_ADDRESS =
  process.env.REACT_APP_GENESIS_COLLECTION_ADDRESS;

export const SOCKBOT_COLLECTION_ADDRESS =
  process.env.REACT_APP_SOCKBOT_COLLECTION_ADDRESS;

export const SOCKBOT_V2_COLLECTION_ADDRESS =
  process.env.REACT_APP_SOCKBOT_V2_COLLECTION_ADDRESS;

export const MASTER_API_URL = process.env.REACT_APP_ALGOSOCKS_API_URL;

export const IPFS_GATEWAY = 'https://ipfs.algonft.tools/ipfs/'
export const IPFS_GATEWAY2 = 'https://ipfs.io/ipfs/';

export const BLOCK_EXPLORER_ASSET_URL = 'https://allo.info/asset';
export const BLOCK_EXPLORER_ACCOUNT_URL = 'https://allo.info/account';
