import { Flex, Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const NFTLevel = ({ claimed, nftLevel, xp }) => {
  // Calculate the width of the progress bar based on current XP
  let progressBarWidth = 0
  
  if (nftLevel && nftLevel.maxXp > 0) {
    progressBarWidth = (xp / nftLevel.maxXp) * 100;
  }

  return (
    <>
      {nftLevel && (
        <Heading
          as="h3"
          color="#9F67C8"
          fontSize="16px"
          mb="2"
          fontWeight={'normal'}
          textAlign="center"
        >
          Level {nftLevel.level} • {nftLevel.levelName}
        </Heading>
      )}

      <Flex
        align="stretch"
        pos={'relative'}
        justify="stretch"
        mb="4"
        borderRadius={'full'}
        bg="purple.100"
        w="100%"
        maxW={'300px'}
        h="30px"
      >
        <Flex
          align={'center'}
          pos={'absolute'}
          w="100%"
          h="100%"
          top={0}
          left={0}
          mx={'auto'}
          textAlign={'center'}
        >
          <Text color="white" fontSize="12px" fontWeight={'normal'} ml={4}>
            {xp} XP
          </Text>
        </Flex>
        <Flex
          as={motion.div}
          initial={{ width: 0 }}
          animate={{ width: `${progressBarWidth}%` }}
          align={'center'}
          justify={'center'}
          bg="#FC0174"
          borderRadius={'full'}
        ></Flex>
      </Flex>
    </>
  );
};

export default NFTLevel;
