import React, { useEffect } from 'react';
import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Portfolio from './pages/Portfolio/Portfolio';
import Auctions from './pages/Auctions/Auctions';
import SockSwap from './pages/SockSwap/SockSwap';
import Marketplace from './pages/Marketplace/Marketplace';
import Nav from './components/Nav/Nav';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UserProvider from './providers/UserProvider';
import './App.css'
import { Flex } from '@chakra-ui/react';
import {
  reconnectProviders,
  useInitializeProviders,
  WalletProvider,
  PROVIDER_ID,
} from '@txnlab/use-wallet';
import '@fontsource/poppins/700.css';
import '@fontsource/inter/400.css';
import { PeraWalletConnect } from '@perawallet/connect';
import { DeflyWalletConnect } from '@blockshake/defly-connect';

const routes = [
  {
    path: '/',
    component: <Home />,
  },
  {
    path: '/marketplace',
    component: <Marketplace />,
  },
  {
    path: '/auctions',
    component: <Auctions />,
  },
  {
    path: '/portfolio',
    component: <Portfolio />,
  },
  {
    path: '/sockswap',
    component: <SockSwap />,
  }
];

const App = () => {

  const walletProviders = useInitializeProviders(
    {
      providers: [
        { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
        { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
        { id: PROVIDER_ID.EXODUS }
      ],
      nodeConfig: {
        network: 'mainnet',
        nodeServer: 'https://mainnet-api.algonode.cloud',
        nodeToken: '',
        nodePort: '443'
      }
    }
  );

  return (
    <BrowserRouter>
      <UserProvider>
        <WalletProvider value={walletProviders}>
          <Flex>
            <Flex
              flexDirection={'column'}
              h={"100vh"}
              position="sticky"
              top="0">
              <Nav />
            </Flex>

            <Flex flexDirection={'column'} overflowY="auto" flex={'1 1'}>
              <Header />
              <Routes>
                {routes.map(route => {
                  return (
                    <Route
                      path={route.path}
                      element={route.component}
                      key={route.path}
                    />
                  );
                })}
              </Routes>
              <Footer />
            </Flex>
          </Flex>
        </WalletProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default App;
