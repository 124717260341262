import { useState, useEffect } from 'react';
import { useWallet } from '@txnlab/use-wallet';

const useTimer = (missionStartDate, missionDuration) => {
  const { isReady } = useWallet();
  const [timeRemaining, setTimeRemaining] = useState(null);



  // Convert back to dd:hh:mm:ss
  const msToTime = time => {
    const milliseconds = parseInt((time % 1000) / 100),
      seconds = Math.floor((time / 1000) % 60),
      minutes = Math.floor((time / (1000 * 60)) % 60),
      hours = Math.floor((time / (1000 * 60 * 60)) % 24),
      days = Math.floor(time / (1000 * 60 * 60 * 24));

    const pad = num => (num < 10 ? '0' + num : num);

    return (
      pad(days) + ':' + pad(hours) + ':' + pad(minutes) + ':' + pad(seconds)
    );
  };

  useEffect(() => {
    if (isReady) {
      if (missionStartDate === null) {
        // Code for calculating the initial time remaining when missionStartDate is null...
      } else {
        const currentTime = new Date().getTime();
        const startTime = new Date(missionStartDate).getTime();
        
        const [days, hours, minutes, seconds] = missionDuration.split(':').map(Number);
        const durationInMs = ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60) + seconds) * 1000;
  
        const endTime = startTime + durationInMs;
        const initialTimeRemaining =
          endTime - currentTime > 0 ? endTime - currentTime : 0;
  
        setTimeRemaining(initialTimeRemaining);
      }
    }
  }, [isReady, missionDuration, missionStartDate]);

  
  

  useEffect(() => {
    if (timeRemaining !== null) {
      const interval = setInterval(() => {
        setTimeRemaining(prevTimeRemaining => {
          if (prevTimeRemaining <= 1000) {
            clearInterval(interval);
            return 0;
          }
          return prevTimeRemaining - 1000;
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [timeRemaining]);

  return timeRemaining !== null
    ? timeRemaining <= 0
      ? '00:00:00:00'
      : msToTime(timeRemaining)
    : 'Loading...';
};

export default useTimer;
