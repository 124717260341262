import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Text,
  Button,
  Box,
  HStack,
  Icon,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  useToast,
  Drawer,
  DrawerBody, 
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import BotAvatar from '../BotAvatar/BotAvatar';
import { ArrowBackIcon, CopyIcon } from '@chakra-ui/icons';
// import { Cart } from '@chakra-ui/icons';

import { useWallet } from '@txnlab/use-wallet';
import { truncateAddress } from '../../utils/algorand';
import WalletDetails from '../WalletDetails/WalletDetails';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import WalletConnect from '../WalletConnect/WalletConnect';
import ShoppingCart from '../ShoppingCart/ShoppingCart';
// import CartIcon from '../CartIcon/CartIcon';

const Header = () => {
  const { activeAccount } = useWallet();
  const toast = useToast();
  const OverlayBG = () => (
    <ModalOverlay bg="rgba(27, 8, 51, 0.5)" backdropFilter="blur(6px)" />
  );

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayBG />);
  const [modalStep, setModalStep] = useState(0);

  // Copy Wallet Address to Clipboard //
  const copyToClipboard = () => {
    let copyText = activeAccount.address;
    navigator.clipboard.writeText(copyText);
    toast({
      description: 'Copied to Clipboard',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const [NFD, setNFD] = useState('');

  // Get NFD from Active Account Wallet Address
  useEffect(() => {
    const getNFD = async () => {
      if (activeAccount) {
        let baseNFDUri = 'https://api.nf.domains/nfd/v2/address';
        let query = `address=${activeAccount.address}`;
        let extraParams = `&limit=1&view=thumbnail`;

        let config = {
          method: 'get',
          url: `${baseNFDUri}?${query}${extraParams}`,
          headers: {
            'If-None-Match': 'etag',
          },
        };

        try {
          axios(config)
            .then(response => {
              let data = response.data;

              for (let NfdEntry in data) {
                if (data) {
                  const caAlgo = data[NfdEntry][0]['caAlgo'][0];
                  const name = data[NfdEntry][0]['name'];
                  if (caAlgo === activeAccount.address) {
                    setNFD(name);
                  }
                }
              }
            })
            .catch(err => {
              setNFD(truncateAddress(activeAccount.address));
            });
        } catch (error) {
          console.error(error);
        }
      }
    };
    getNFD();
  }, [activeAccount, NFD]);

  return (
    <>
      <Flex
        display="flex"
        align="center"
        justify="flex-end"
        maxW="100%"
        pt="4"
        pr="4"
        pl="4"
      >
        {activeAccount ? (
          <>
            <Text fontSize="md" mr="4">
              {NFD}
            </Text>
            <IconButton
              aria-label="Copy Address"
              onClick={() => {
                copyToClipboard();
              }}
              mr="0"
            >
              <Icon as={CopyIcon} boxSize={4} />
            </IconButton>
          </>
        ) : (
          <Text fontSize="md" mr="4">
            Connect Wallet
          </Text>
        )}

        <Flex mx={4} position={'relative'} gap={4} align={'center'}>
          {activeAccount ? (
            <Box
              pos={'absolute'}
              top={'-2px'}
              right={'-2px'}
              bg={'#15C646'}
              borderRadius={'full'}
              border={'1px solid #B810FF'}
              width={4}
              height={4}
              zIndex={100}
            ></Box>
          ) : (
            <Box
              pos={'absolute'}
              top={'-2px'}
              right={'-2px'}
              bg={'#707070'}
              borderRadius={'full'}
              border={'1px solid #B810FF'}
              width={4}
              height={4}
              zIndex={100}
            ></Box>
          )}

          {/* <IconButton
          aria-label="Open Cart"
          onClick={onDrawerOpen}
          icon={<CartIcon boxsize={6} />}
          /> */}

          <IconButton
            aria-label="Open Menu"
            onClick={() => {
              setOverlay(<OverlayBG />);
              onModalOpen();
            }}
            variant="clean"
            size="custom"
            isRound={true}
            icon={<BotAvatar size="1" />}
          />
        </Flex>
      </Flex>

      {/* Shopping Cart Drawer */}
      <Drawer 
        isOpen={isDrawerOpen} 
        placement="right" 
        onClose={onDrawerClose} 
        size="lg" 
      >
        <DrawerOverlay>
          <DrawerContent bgGradient={'linear(to-l, purple.300, purple.200)'}>
            <DrawerCloseButton />
            <DrawerHeader>Shopping Cart</DrawerHeader>

            <DrawerBody>
              <ShoppingCart />
            </DrawerBody>

            <DrawerFooter>
              <Button 
                onClick={onDrawerClose}
                variant={'outline'}
                borderRadius={'lg'}
              >
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <Modal isOpen={isModalOpen} onClose={onModalClose} zIndex={10}>
        {overlay}
        <ModalContent
          borderRadius={'15px'}
          bg={'#1B0833'}
          border={'2px solid #8A2699'}
        >
          <ModalHeader p={0}>
            {/* Menu Header */}
            <HStack
              bg="#2b083d"
              borderRadius={'15px 15px 0 0'}
              padding={'1rem'}
              // border={'1px solid #A740B7'}
              w={'100%'}
              justifyContent={'space-between'}
            >
              {/* Wallet Address and Copy Button */}
              {modalStep === 0 ? (
                <>
                  {activeAccount ? (
                    <>
                      <HStack>
                        <Text fontSize={'md'}>
                          {truncateAddress(activeAccount.address)}
                        </Text>
                        <IconButton
                          aria-label="Copy Address"
                          onClick={() => {
                            copyToClipboard();
                          }}
                        >
                          <Icon as={CopyIcon} boxSize={4} />
                        </IconButton>
                      </HStack>
                      <Button
                        variant={'outline'}
                        justifySelf={'flex-end'}
                        onClick={() => {
                          setModalStep(1);
                        }}
                      >
                        {activeAccount ? 'Switch Wallet' : 'Connect Wallet'}
                      </Button>
                    </>
                  ) : (
                    <>
                      <HStack>
                        <Text>No Wallet Connected</Text>
                      </HStack>

                      <Button
                        variant={'outline'}
                        justifySelf={'flex-end'}
                        onClick={() => {
                          setModalStep(1);
                        }}
                      >
                        {activeAccount ? 'Switch Wallet' : 'Connect Wallet'}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <HStack>
                  <IconButton onClick={() => setModalStep(0)}>
                    <Icon as={ArrowBackIcon} boxSize={4} />
                  </IconButton>
                  {modalStep === 1 && (
                    <Text px={4}>Back to Wallet Details</Text>
                  )}
                </HStack>
              )}
            </HStack>
          </ModalHeader>

          <Carousel
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            showArrows={false}
            swipeable={false}
            // selectedItem={modelStep}
            selectedItem={modalStep}
          >
            <WalletDetails modalStep={modalStep} />
            <WalletConnect />
          </Carousel>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Header;
