import { Flex, Heading, Link } from '@chakra-ui/layout';

const Footer = () => {
  return (
    <Flex 
      // h="100%" 
      align="flex-end" 
      justify="center" 
      gap={4} 
      mt="10" 
      mb="10" 
      px={2} 
      flexWrap={'wrap'} 
    >
      <Heading as="h4" fontSize="md" fontWeight={400}>
        SockHodler x SmartSeal.io
      </Heading>
      <Heading as="h4" fontSize="md" fontWeight={400}>
        Powered by &nbsp;
        <Link href="https://nodely.io/" isExternal>Algonode.io</Link>
      </Heading>
    </Flex>
  );
};

export default Footer;
