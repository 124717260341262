import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CheckIcon, LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { useBreakpointValue } from '@chakra-ui/react';
import useTimer from '../../hooks/useTimer';
import {
  updateMission,
  getMissionProgress,
  claimAllRewards,
} from '../../services/MissionService';
import { incrementXP } from '../../services/UserService';
import { useWallet } from '@txnlab/use-wallet';
import { makeOptInAssetTxn } from '../../utils/algorand';
import algosdk from 'algosdk';
import { generateTransactions } from '../../utils/missionUtils';

const Mission = ({
  mission_id,
  asa_id,
  user_wallet_address,
  title,
  image,
  requirements,
  description,
  rewards,
  userNftData,
  isEligible,
  type,
  duration,
  onUpdateXp,
}) => {
  const eligible = isEligible(userNftData, requirements);

  const { activeAccount, isReady, signTransactions, sendTransactions } =
    useWallet();

  const stackDirection = useBreakpointValue({
    base: 'column',
    sm: 'column',
    md: 'column',
    lg: 'column',
    xl: 'row',
  });

  const imageWidth = useBreakpointValue({
    base: '100%',
    sm: '100%',
    md: '100%',
    lg: '100%',
    xl: '400px',
  });

  const toast = useToast();

  const missionDuration = duration;

  const [missionProgress, setMissionProgress] = useState('Not Started');
  const [missionStartDate, setMissionStartDate] = useState(null);
  const [loading, setLoading] = useState(false);

  let timeRemaining = useTimer(
    missionProgress === 'In Progress' ? missionStartDate : null,
    missionDuration
  );

  const handleOptInTxn = async (address, tokenId) => {
    toast({
      title: 'Opting in...',
      description: 'Please sign the transaction in your wallet.',
      status: 'info',
      duration: 9000,
      isClosable: true,
    });
    const txn = await makeOptInAssetTxn(address, tokenId);
    const encodedTxn = algosdk.encodeUnsignedTransaction(txn);
    const signedTxn = await signTransactions([encodedTxn]);
    const waitRoundsToConfirm = 4;
    const { id } = await sendTransactions(signedTxn, waitRoundsToConfirm);
    toast({
      title: 'Opt-in transaction sent',
      description: 'Waiting for confirmation...',
      status: 'info',
      duration: 4000,
      isClosable: true,
    });
    return id;
  };

  const handleStartMission = async () => {
    if (isReady) {
      try {
        setLoading(true);
        const txnId = await handleOptInTxn(activeAccount.address, asa_id);
        if (txnId) {
          setMissionProgress('In Progress');
          const startDate = new Date();
          setMissionStartDate(startDate);
          await updateMission(asa_id, mission_id, 'In Progress', startDate);
          toast({
            title: 'Success',
            description: 'Mission started successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error(error);
        toast({
          title: 'Error',
          description: 'There was an error starting mission.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClaimReward = async () => {
    if (isReady) {
      try {
        setLoading(true);

        // Handle non-XP assets like SOCKS Tokens or NFTs
        const nonXPAssets = rewards.filter(reward => reward.type !== 'XP');

        // Generate necessary optins for the blockchain rewards
        const optInTxns = await Promise.all(generateTransactions(user_wallet_address, nonXPAssets));

        const encodedOptInTxns = algosdk.encodeUnsignedTransaction(optInTxns);

        const signedTxn = await signTransactions([encodedOptInTxns]);

        const waitRoundsToConfirm = 4;
        const { id } = await sendTransactions(signedTxn, waitRoundsToConfirm);

        if (id) {
          // Handle incrementing for the XP part of rewards
          const xpReward = rewards.find(reward => reward.type === 'XP');

          let level;
          
          // If there is an XP reward for the mission - FYI there always will be
          if (xpReward) {

            // Updates the level in Dashboard Component and returns new level
            const level = await onUpdateXp(xpReward.value);

            // Increment XP for the user in the DB
            await incrementXP(asa_id, xpReward.value, level);
          }

          // Claim all rewards not relating to XP
          await claimAllRewards(
            asa_id,
            user_wallet_address,
            mission_id,
            rewards,
            level.level
          );

          // Update mission status to completed in DB and State
          await updateMission(asa_id, mission_id, 'Completed');
          setMissionProgress('Completed');

          toast({
            title: 'Success',
            description: 'Mission Rewards claimed successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (err) {
        console.error(err);
        toast({
          title: 'Error',
          description: 'There was an error while claiming rewards.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // Fetch mission status on component mount
    const fetchMissionProgress = async () => {
      try {
        const missionProgressData = await getMissionProgress(asa_id);
        const missionProgress = missionProgressData.find(
          item => item.mission_id === mission_id
        );

        if (missionProgress) {
          setMissionProgress(missionProgress.status);
          setMissionStartDate(new Date(missionProgress.start_date));
        }
      } catch (err) {
        console.error('Failed to fetch mission status', err);
      }
    };

    fetchMissionProgress();
  }, [asa_id, mission_id]);

  // Update the mission status when timeRemaining reaches zero
  useEffect(() => {
    const updateMissionStatus = async () => {
      if (
        timeRemaining === '00:00:00:00' &&
        missionProgress === 'In Progress'
      ) {
        try {
          await updateMission(asa_id, mission_id, 'Reward Available');
          setMissionProgress('Reward Available');
        } catch (err) {
          console.error('Reward Available Status not updated as intended', err);
        }
      }
    };

    updateMissionStatus();
  }, [timeRemaining, missionProgress, mission_id, asa_id]);

  const renderReward = (reward, index) => {
    if (reward.type === 'XP') {
        return <Text key={index}>XP: {reward.value}</Text>;
    }
    if (reward.type === 'SOCKS Tokens') {
        return <Text key={index}>SOCKS Tokens: {reward.value}</Text>;
    }
    if (reward.type === 'Optional Asset') {
        return <Text key={index}>Additional Asset: {reward.asa_id}</Text>;
    }
    return null;
}


  return (
    <Card
      bg={missionProgress === 'Completed' ? 'green.800' : 'purple.200'}
      color={'white'}
      my={4}
      overflow={'hidden'}
      display={'flex'}
      borderRadius={'xl'}
      opacity={
        missionProgress === 'Completed' ? '.50' : !eligible ? '.75' : '1'
      }
      filter={!eligible ? 'grayscale(100%)' : 'none'}
      maxW={{
        xs: '100%',
        sm: '100%',
        md: '100%',
        lg: '100%',
        xl: '100%',
        '2xl': '100%',
      }}
    >
      <CardHeader
        bgGradient={
          missionProgress === 'Completed'
            ? 'linear(to-t, green.900, green.800)'
            : 'linear(to-t, purple.300, purple.200)'
        }
        border={'1px solid'}
        borderColor={
          missionProgress === 'Completed' ? 'green.900' : 'purple.900'
        }
        borderRadius={'xl'}
        alignItems={'center'}
        display={'flex'}
        flexDirection={{ base: 'column', lg: 'row' }}
        gap={{ base: 1, lg: 2 }}
      >
        <Heading as={'h4'} fontSize={{ base: 'md', md: 'lg' }} mb={2}>
          {title}
        </Heading>

        <Flex wrap={'wrap'} gap={{ base: 1, lg: 4 }}>
          {missionProgress === 'Completed' ? (
            <Badge bg={'green'} padding={'.25rem 1rem'} mb={2} color={'white'}>
              {missionProgress}
            </Badge>
          ) : missionProgress === 'In Progress' ? (
            <Badge bg={'purple'} padding={'.25rem 1rem'} mb={2} color={'white'}>
              {missionProgress}
            </Badge>
          ) : (
            <Badge bg={'red'} padding={'.25rem 1rem'} mb={2} color={'white'}>
              {missionProgress}
            </Badge>
          )}

          {type === 'Special' ? (
            <Badge bg={'orange'} padding={'.25rem 1rem'} mb={2} color={'white'}>
              {type}
            </Badge>
          ) : (
            <Badge bg={'teal'} padding={'.25rem 1rem'} mb={2} color={'white'}>
              {type}
            </Badge>
          )}
        </Flex>
      </CardHeader>
      <CardBody>
        <Stack
          direction={stackDirection}
          justify={'space-between'}
          width={'100%'}
          align={'stretch'}
        >
          <Box p={4} flexGrow={1}>
            <Text maxW={'350px'} mb={4}>
              {description}
            </Text>

            <Text fontWeight={'bold'} color={'teal.300'}>
              Requirements:
            </Text>
            <Box mb={4}>
              {requirements &&
                requirements.traits.map((req, index) => {
                  return <Text key={index}>{req}</Text>;
                })}
              Level: {requirements.level}
            </Box>

            <Box mb={4}>
    <Text fontWeight={'bold'} color={'teal.300'}>
        Rewards:
    </Text>
    {rewards.map((reward, index) => renderReward(reward, index))}
</Box>

            <Box mb={4}>
              <Text fontWeight={'bold'} color={'teal.300'}>
                Duration:
              </Text>
              <Text>{duration}</Text>
            </Box>

            <Box mb={4}>
              <Text fontWeight={'bold'} color={'teal.300'}>
                Time Remaining:
              </Text>

              <Text color={'white'}>
                {missionProgress === 'In Progress'
                  ? timeRemaining === '00:00:00:00'
                    ? "Time's Up"
                    : timeRemaining
                  : 'Not Started'}
              </Text>
            </Box>
            {missionProgress === 'Completed' ? (
              <CheckIcon
                position={'absolute'}
                top={4}
                right={4}
                color={'green.500'}
                zIndex={10}
              />
            ) : eligible ? (
              <UnlockIcon
                position={'absolute'}
                top={4}
                right={4}
                color={'green.500'}
                zIndex={10}
              />
            ) : (
              <LockIcon
                position={'absolute'}
                top={4}
                right={4}
                color={'red.500'}
                zIndex={10}
              />
            )}
          </Box>
          <Box
            position={'relative'}
            width={imageWidth}
            overflow={'hidden'}
            display={'flex'}
            justifyContent={'stretch'}
            alignItems={'stretch'}
            borderRadius={'xl'}
          >
            <Image
              src={image}
              height={'100%'}
              maxH={'100%'}
              objectFit={'cover'}
              transform={'scale(1)'}
              transition={'all 0.4s ease-in-out'}
              width={imageWidth}
              _hover={{
                transform: 'scale(1.1)',
              }}
            />
            <Button
              position={'absolute'}
              bottom={4}
              right={4}
              variant={
                loading ||
                !eligible ||
                missionProgress === 'Completed' ||
                missionProgress === 'In Progress'
                  ? 'disabled'
                  : 'outline'
              }
              onClick={
                missionProgress === 'Not Started'
                  ? handleStartMission
                  : missionProgress === 'Reward Available'
                  ? handleClaimReward
                  : null
              }
              isDisabled={
                !eligible ||
                loading ||
                missionProgress === 'Completed' ||
                missionProgress === 'In Progress'
              }
            >
              {!eligible
                ? 'Ineligible'
                : loading
                ? 'Loading...'
                : missionProgress}
            </Button>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default Mission;
