import React from 'react';
import {
  VStack,
  Box,
  Text,
  Flex,
  Heading,
  Link,
  Divider,
} from '@chakra-ui/react';
import { truncateAddress } from '../../utils/algorand';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const CheckoutConfirmation = ({ cartItems, total }) => {
  const testTxnID = 'AWY5U4FERZUA4QVJG7QPBWM57MT7M5XYADQOADCRJ34Q2P2QUX5Q';

  return (
    <VStack spacing={4} alignItems="stretch" gap={4}>
      <Heading as={'h2'} fontSize={'xl'}>
        Checkout Confirmation
      </Heading>

      <Text>Thank you for your purchase!</Text>

      <Flex align={'center'} flexDir={'row'}>
        <Text>Transaction ID:&nbsp;</Text>
        <Link href="#" isExternal>
          {truncateAddress(testTxnID)}
          <ExternalLinkIcon mx={1} />
        </Link>
      </Flex>

      <Box>
        <Heading as={'h3'} fontSize={'md'} mb={2}>
          Order Summary
        </Heading>
        <Divider mb={4} />

        <VStack spacing={2} align={'stretch'} gap={2}>
          {cartItems.map(item => (
            <Box>
              <Box key={item.id} mb={4}>
                <Text>{item.name}</Text>
                <Text>Quantity: {item.quantity}</Text>
                <Text>Price: ${(item.price * item.quantity).toFixed(2)}</Text>
              </Box>
              <Divider />
            </Box>
          ))}
        </VStack>
      </Box>
      <Text fontWeight="bold">Total: ${total.toFixed(2)}</Text>
    </VStack>
  );
};

export default CheckoutConfirmation;
