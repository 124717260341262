import axios from 'axios';
import { MASTER_API_URL } from '../config/constants';

const BASE_URL = `${MASTER_API_URL}/sockvault/users`;

export const updateLastScanDate = async (scan_date, address) => {
  axios.post(BASE_URL, {
      user_wallet_address: address,
      last_scan_date: scan_date,
  })
  .catch(err => {
    throw new Error(err);
  })
};

export const updateAchievementBadges = async (badge, address) => {
  console.log('badge', badge);
  axios.post(BASE_URL, {
    user_wallet_address: address,
    badges_unlocked: badge
  })
  .catch(err => {
    throw new Error(err);
  })
};

export const incrementXP = async (asa_id, xpRewardValue, level) => {
  try {
    const res = await axios.post(`${BASE_URL}/xp`, {
      asa_id,
      xp: xpRewardValue,
      level
    });
    console.log('XP Claimed', res);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getUser = async (user_wallet_address) => {
  return axios.get(`${BASE_URL}/${user_wallet_address}`);
};
