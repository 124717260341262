import React, { useEffect, useState, useContext } from 'react';
import Mission from '../Mission/Mission';
import { Checkbox, Text, Flex } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { getMissions } from '../../services/MissionService';
import { userContext } from '../../providers/UserProvider';
import { useWallet } from '@txnlab/use-wallet';
import { getTraitsARC69 } from '../../utils/arcSpec';
// import { xpRange } from '../../utils/xpRange';

const Missions = ({ onUpdateXp }) => {
  const { activeAccount, isReady } = useWallet();
  const [missions, setMissions] = useState([]);
  const [showAvailable, setShowAvailable] = useState(false);
  const [userNftData, setUserNftData] = useState({ level: 1, traits: [''] });

  const { scannedNFT, nftLevel } = useContext(userContext);

  useEffect(() => {
    if (isReady) {
      const handleUserNFTData = async () => {
        if (!scannedNFT) return;

        const traits = await getTraitsARC69(scannedNFT);

        if (nftLevel) {
          setUserNftData({
            level: nftLevel.level,
            traits: Object.values(traits.properties),
          });
        }
      };

      const fetchMissions = async () => {
        if (!missions.length) {
          const data = await getMissions();
          setMissions(data);
        }
      };

      handleUserNFTData();
      fetchMissions();
    }
  }, [missions, scannedNFT, nftLevel, activeAccount, isReady]);

  if (!isReady) {
    return (
      <Flex py={4} textAlign="center">
        <Text fontSize="xl">Please Connect Wallet to view missions</Text>
      </Flex>
    );
  }

  const handleCheckboxChange = e => {
    setShowAvailable(e.target.checked);
  };

  const isEligible = (userNftData, requirements) => {
    if (!requirements) return false;

    // Check the level first
    if (userNftData.level < requirements.level) {
      return false;
    }

    // If the mission has required traits, check if the user NFT has these traits
    if (requirements.traits && requirements.traits.length > 0) {
      // Exclude empty strings
      const requiredTraits = requirements.traits.filter(trait => trait !== '');
      if (requiredTraits.length > 0) {
        for (const trait of requiredTraits) {
          // Check if the user's traits is an array and includes the required trait
          if (
            !Array.isArray(userNftData.traits) ||
            !userNftData.traits.includes(trait)
          ) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const filteredMissions = missions.filter(mission => {
    if (!showAvailable) {
      return true;
    }
    if (isReady) {
      const eligible = isEligible(userNftData, mission.requirements);
      return eligible && mission.status !== 'Completed';
    }
    return false; // If the user is not ready, don't show any missions
  });

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 },
  };

  return (
    <>
      <Checkbox
        mb={4}
        colorScheme="teal"
        isChecked={showAvailable}
        onChange={handleCheckboxChange}
      >
        Show Available Missions
      </Checkbox>

      <AnimatePresence>
        {filteredMissions.map(mission => (
          <motion.div
            key={mission.id}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
          >
            <Mission
              key={mission.id}
              mission_id={mission.mission_id}
              asa_id={scannedNFT} // Pull from the User Context
              user_wallet_address={activeAccount ? activeAccount.address : null}
              title={mission.title}
              image={mission.image}
              requirements={mission.requirements}
              description={mission.description}
              rewards={mission.rewards}
              userNftData={userNftData}
              isEligible={isEligible}
              type={mission.type}
              duration={mission.duration}
              onUpdateXp={onUpdateXp}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </>
  );
};

export default Missions;
