import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  SliderMark,
  Tooltip,
  useToast,
  Stack,
  Flex,
} from '@chakra-ui/react';
import algosdk, {
  makeAssetTransferTxnWithSuggestedParamsFromObject,
} from 'algosdk';
import { useWallet } from '@txnlab/use-wallet';
import { MASTER_API_URL } from '../../config/constants';
import { algodClient } from '../../utils/algorand';

const Swapper = ({ token, address }) => {
  const [transactionStatus, setTransactionStatus] = useState('');
  const [amount, setAmount] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const toast = useToast();

  const { activeAccount, signTransactions, sendTransactions } =
    useWallet();

  const usdValue = parseFloat(amount) * token.valueInUSD;

  const fetchBalance = useCallback(async () => {
    try {
      const accountInfo = await algodClient.accountInformation(address).do();
      const asset = accountInfo.assets.find(a => a['asset-id'] === token.id);

      if (asset) {
        const decimals = token.decimals;
        const balance = asset.amount / Math.pow(10, decimals);
        setUserBalance(balance);
      } else {
        setUserBalance(0);
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
      setUserBalance(0);
    }
  }, [token, address]);

  useEffect(() => {
    if (token) {
      fetchBalance();
      setAmount(0);
      setSliderValue(0);
    }
  }, [token, fetchBalance]);
  

  useEffect(() => {
    setAmount(0);
    setSliderValue(0);
  }, [address]);

  const formatBalance = balance => {
    const decimalPlaces = Math.min(token.decimals, 2);
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(balance);
  };

  const formatAmount = value => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  const parseAmount = value => {
    return value.replace(/,/g, '');
  };

  const handleTransaction = async () => {
    setTransactionStatus('Sending transaction...');
    toast({
      title: 'Sending Transaction.',
      description: `Please sign using your wallet.`,
      status: 'info',
      duration: 9000,
      isClosable: true,
    });
    try {
      let params = await algodClient.getTransactionParams().do();
      let enc = new TextEncoder();
      let note = enc.encode('Coupon code generation');
      const receiverAddress =
        'SOCKSV3B6CDAE5R4BS5RZYEVMC3UGR4N76C3C7UZQ6KTTPLTI7DSXWMX6Y';
      const amountInDecimals = Math.floor(amount.toFixed(2) * Math.pow(10, 6));
      const transaction = makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: activeAccount.address,
        to: receiverAddress,
        amount: amountInDecimals,
        assetIndex: token.id,
        note: note,
        suggestedParams: params,
      });

      const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction);

      const signedTransaction = await signTransactions([encodedTransaction]);

      const waitRoundsToConfirm = 4;

      const confirmedTxn = await sendTransactions(
        signedTransaction,
        waitRoundsToConfirm
      );

      console.log('Successfully sent transaction. TXN ID:', confirmedTxn);
      toast({
        title: 'Transaction Sent Successfully.',
        description: `Waiting on Coupon Code Generation.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      // // Check if the transaction was successful
      if (confirmedTxn['confirmed-round'] !== null) {
        const response = await axios.post(
          `${MASTER_API_URL}/sockvault/generate-coupons`,
          {
            assetId: token.id,
            amount: amount,
            senderAddress: activeAccount.address,
          }
        );
        const couponCode = response.data.code;
        setTransactionStatus(`${couponCode}`);
        toast({
          title: 'Coupon code generated successfully.',
          description: `Coupon code: ${couponCode}.  Please copy or print before reloading the page.`,
          status: 'success',
          duration: 15000,
          isClosable: true,
        });
      } else {
        setTransactionStatus('Transaction failed. Please try again.');
        toast({
          title: 'Coupon Generation Failed.',
          description: 'Please contact admin on Discord.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error generating coupon code:', error);
      console.log('Error message:', error.message);

      if (error.message === 'Rejected by user') {
        // The user rejected the transaction
        toast({
          title: 'Transaction Rejected',
          description: 'You have rejected the transaction.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        // Other error occurred
        toast({
          title: 'Error',
          description: 'An error occurred while processing the transaction.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
      setTransactionStatus('');
    }
  };

  const handleAmountChange = value => {
    if (value > userBalance) {
      value = userBalance;
    }
    setAmount(value);
    setSliderValue(Math.round((value / userBalance) * 100).toFixed(2));
  };

  const handleSliderChange = value => {
    setSliderValue(value);
    setAmount(Math.round((userBalance * value) / 100));
  };

  const formatTokenValue = value => {
    const decimalPlaces = 6;
    const formattedValue = parseFloat(value.toFixed(decimalPlaces));
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(formattedValue);
  };

  return (
    <Box>
      <Text mt={2}>
        Selected token: {token.name} - ${formatTokenValue(token.valueInUSD)} USD
      </Text>
      <Text mb={6}>
        <small>User balance: {token ? formatBalance(userBalance) : '0'}</small>
      </Text>
      <FormControl mb={6}>
        <FormLabel>Amount:</FormLabel>
        <NumberInput
          value={amount.toFixed(2)}
          min={0}
          max={userBalance}
          onChange={value => handleAmountChange(parseFloat(value))}
          format={formatAmount}
          parse={parseAmount}
          isDisabled={userBalance === 0}
        >
          <NumberInputField />

          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <FormControl mb={6}>
        <FormLabel>Percentage of balance:</FormLabel>
        <Slider
          min={0}
          max={100}
          defaultValue={25}
          step={5}
          colorScheme="teal"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          value={sliderValue}
          onChange={handleSliderChange}
          isDisabled={userBalance === 0}
        >
          <SliderMark value={25} mt="1" ml="-2.5" fontSize="sm">
            25%
          </SliderMark>
          <SliderMark value={50} mt="1" ml="-2.5" fontSize="sm">
            50%
          </SliderMark>
          <SliderMark value={75} mt="1" ml="-2.5" fontSize="sm">
            75%
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <Tooltip
            hasArrow
            bg="teal.500"
            color="white"
            placement="top"
            isOpen={showTooltip}
            label={`${sliderValue}%`}
          >
            <SliderThumb />
          </Tooltip>
        </Slider>
      </FormControl>

      <FormControl mb={6}>
        <FormLabel>Total USD value:</FormLabel>
        <NumberInput value={usdValue.toFixed(2)} precision={2} isReadOnly>
          <NumberInputField />
        </NumberInput>
      </FormControl>

      {/* // TODO - Add a check to see if the wallet isReady */}
      {userBalance === 0 ||
      amount === 0 ||
      !activeAccount ||
      transactionStatus === 'Sending transaction...' ? (
        <Flex justify={'center'}>
          <Button
            variant={'disabled'}
            px={20}
            py={6}
            isDisabled
            cursor={'not-allowed'}
          >
            Swap Tokens for Voucher
          </Button>
        </Flex>
      ) : (
        <Flex justify={'center'}>
          <Button
            variant={'solidPurple'}
            onClick={() => {
              console.log(sliderValue);
              handleTransaction();
            }}
            px={20}
            py={6}
          >
            Swap Tokens for Voucher
          </Button>
        </Flex>
      )}

      {transactionStatus && (
        <div className="printable">
          <Stack
            direction="column"
            spacing={4}
            align="center"
            justify={'center'}
          >
            <Box
              bg={'purple.800'}
              py={4}
              px={8}
              mt={10}
              borderRadius={'md'}
              boxShadow={'md'}
              w={'100%'}
              textAlign={'center'}
            >
            
              <Text color={'white'} fontWeight={'bold'} mb={2}>
                SockHodler Voucher
              </Text>
              <Text color={'white'} mb={2}>
                <small>Your coupon code is:</small> <br />
                {transactionStatus}
              </Text>
              <Text color={'white'} mt={'10'}>
                You will receive ${usdValue.toFixed(2)} off your next sock
                order.
              </Text>
              <Text color={'white'}>
                <small>Shipping not included.</small>
              </Text>
              <Button variant={'outline'} onClick={() => window.print()} mt={4}>
                Print Voucher
              </Button>
            </Box>
          </Stack>
        </div>
      )}
    </Box>
  );
};

export default Swapper;
