import iconNFC from '../../assets/icons/auth_nfc.svg'
import { Image, keyframes } from "@chakra-ui/react";

const glow = keyframes`
    from {
    filter: drop-shadow(0 0 0.25rem rgba(138, 38, 153, 1));
    -webkit-filter: drop-shadow(0 0 0.25rem rgba(138, 38, 153, 1));
  }
  to {
    filter: drop-shadow(0 0 1rem rgba(252, 1, 116, 0.75));
    -webkit-filter: drop-shadow(0 0 1rem rgba(252, 1, 116, 0.5));
  }    
`

const glowAnimation = `${glow} 3s ease-in-out infinite alternate`;

const AuthIcon = ({ icon }) => {
  return (
      <>
        <Image src={icon ? icon : iconNFC} animation={glowAnimation} />
      </>
  )
}

export default AuthIcon;
