import {
  Heading,
  Flex,
  VStack,
  Button,
  Stack,
  Text,
  Box,
  Skeleton,
  Link,
  useToast,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import React, { useContext, useState } from 'react';
import {
  reformatIpfsUrl,
  truncateAddress,
} from '../../utils/algorand';
import { RedeemService } from '../../services/RedeemService';
import { useNFTContent } from '../../hooks/useNFTContent';
import { userContext } from '../../providers/UserProvider';
import axios from 'axios';
import { MASTER_API_URL } from '../../config/constants';
import { useWallet } from '@txnlab/use-wallet';
import algosdk from 'algosdk';
import { BLOCK_EXPLORER_ACCOUNT_URL } from '../../config/constants';

const NFT = ({
  uid,
  asaName,
  asaId,
  ownerAddress,
  creatorAddress,
  totalSupply,
  reserveAddress,
  url,
}) => {

  const { activeAccount, signTransactions, sendTransactions } = useWallet();
  const { setOwnsNFT, user, ownsNFT } = useContext(userContext);
  const [isLoading, setIsLoading] = useState(false);

  const nftData = [
    {
      label: 'Token ID',
      value: asaId,
      url: `${BLOCK_EXPLORER_ACCOUNT_URL}/${asaId}`,
    },
    {
      label: 'Owner Address',
      value: truncateAddress(ownerAddress),
      url: `${BLOCK_EXPLORER_ACCOUNT_URL}/${ownerAddress}`,
    },
    {
      label: 'Creator Address',
      value: truncateAddress(creatorAddress),
      url: `${BLOCK_EXPLORER_ACCOUNT_URL}/${creatorAddress}`,
    },
    {
      label: 'Reserve Address',
      value: truncateAddress(reserveAddress),
    },
    {
      label: 'Total Supply',
      value: totalSupply,
    },
    {
      label: 'URL',
      value: truncateAddress(url),
      url: reformatIpfsUrl(url),
    },
    {
      label: 'UID',
      value: uid,
    },
  ];

  const imageProps = {
    url: url,
    reserveAddr: reserveAddress,
  };

  const { content, contentComponent } = useNFTContent(imageProps);
  const toast = useToast();

  const handleRedeemButtonClick = async () => {
    try {
      setIsLoading(true);
      if (activeAccount && activeAccount.address) {

        // Redeem the NFT
        const txn = await RedeemService(activeAccount.address, asaId);
        if(txn) {
          const encodedTransaction = algosdk.encodeUnsignedTransaction(txn);
          const signedTransaction = await signTransactions([encodedTransaction]);
          const waitRoundsToConfirm = 4;
          const { id } = await sendTransactions(signedTransaction, waitRoundsToConfirm);
          toast({
            title: 'Sending Transaction...',
            description: 'The transaction is being sent to the network.',
            status: 'info',
            duration: 4000,
            isClosable: true,
          });
          console.log("Transaction ID:", id);

          await axios.post(`${MASTER_API_URL}/sockvault/claimnfts`, {
              user_wallet_address: activeAccount.address,
              asa_id: asaId,
          });
          // If the redeem process is successful, update the ownsNFT in the context
          setOwnsNFT(true);
          setIsLoading(false);
          toast({
            title: 'NFT Redeemed.',
            description: 'You have successfully redeemed this NFT.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        console.error('Wallet is not connected');
        setIsLoading(false);
        toast({
          title: 'Wallet is not connected.',
          description: 'Please connect your wallet to redeem this NFT.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error redeeming NFT:", error);
      setIsLoading(false);
      toast({
        title: "Error redeeming NFT.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
};

const isRedeemDisabled = ownsNFT || !activeAccount || !activeAccount.address || isLoading;

  return (
    <Flex flex={'1 1 100%'} flexDir={'column'}>
      <Heading
        mt={{ sm: '10px', md: '20px', lg: '20px' }}
        mb={'20px'}
        textAlign={'center'}
        fontSize={{
          sm: '24px',
          md: '28px',
          lg: '28px',
          xl: '36px',
          '2xl': '36px',
        }}
      >
        {asaName}
      </Heading>

      <Stack
        direction={{
          sm: 'column',
          md: 'column',
          lg: 'column',
          xl: 'row',
          '2xl': 'row',
        }}
        spacing={6}
        justify={{
          xs: 'center',
          sm: 'center',
          md: 'center',
          lg: 'center',
          xl: 'stretch',
          '2xl': 'stretch',
        }}
        align={'stretch'}
        wrap={'wrap'}
      >
        <VStack flex={'1 1'} mb={'40px'}>
          <Skeleton
            borderRadius="2xl"
            maxW="400px"
            boxShadow="dark-lg"
            border={'1px #501659 solid'}
            overflow={'hidden'}
            mb={'20px'}
            isLoaded={content !== null}
          >
            <Box borderRadius="2xl" overflow={'hidden'}>
              {contentComponent}
            </Box>
          </Skeleton>

          <Button
  size={'lg'}
  variant={isRedeemDisabled ? 'disabled' : 'solidPurple'}
  mb={'40px'}
  onClick={handleRedeemButtonClick}
  isDisabled={isRedeemDisabled}
  isLoading={isLoading}
>
  Redeem NFT
</Button>

        </VStack>

        {/* NFT Details Column */}
        <VStack 
          align={'stretch'} 
          spacing={'20px'} 
          flex={'2 2'}
        >
          {/* NFT Details Rows */}
          {nftData.map(({ label, value, url }) => (
            <Stack
              direction={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
                '2xl': 'row',
              }}
              justify={{
                xs: 'center',
                sm: 'center',
                md: 'space-between',
                lg: 'space-between',
                xl: 'space-between',
                '2xl': 'space-between',
              }}
              bgGradient={'linear(to-l, #501659, #1B0833)'}
              py={4}
              px={6}
              borderRadius={'xl'}
              border={'1px #501659 solid'}
              key={label}
              minW={{
                sm: '100%',
                md: '100%',
                lg: '400px',
                xl: '500px',
                '2xl': '600px',
              }}
            >
              <Box>
                <Text>{label}</Text>
              </Box>
              <Flex flexDir={'row'} gap={2} align={'center'}>
                {url ? (
                  <>
                    <Link href={url} isExternal color={'#FFFFFF'}>
                      <Text>{value}</Text>
                    </Link>
                    <ExternalLinkIcon />
                  </>
                ) : (
                  <Text>{value}</Text>
                )}
              </Flex>
            </Stack>
          ))}
        </VStack>
      </Stack>
    </Flex>
  );
};

export default NFT;
