import axios from "axios";
import { MASTER_API_URL } from "../config/constants";

export const ScanService = async (asa_id, user_wallet_address) => {
  try {
    const response = await axios.post(`${MASTER_API_URL}/sockvault/users/dailyscanrewards`, {
      asa_id,
      user_wallet_address
    });

    return response.data;
  } catch (err) {
    console.error(err);
  }
};
