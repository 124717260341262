import Badge100 from '../assets/achievements/100XP-SockUnlock.svg';
import Badge250 from '../assets/achievements/250XP-HappinessWarmSock.png';

export const achievementBadges = [
  {
    id: 1,
    badge_id: 5,
    name: 'Sock Unlock',
    description: 'Scan 5 times',
    image: Badge100,
  },
  {
    id: 2,
    badge_id: 10,
    name: 'Happiness is a Warm Sock',
    description: 'Scan 10 times',
    image: Badge250,
  },
  {
    id: 3,
    badge_id: 25,
    name: 'Hail to the King, Baby',
    description: 'Scan 25 times',
    image: Badge100,
  },
  {
    id: 4,
    badge_id: 50,
    name: 'Here to Kick Ass and Chew Bubblegum',
    description: 'Scan 50 times',
    image: Badge100,
  },
];
