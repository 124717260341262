import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  Text,
  Link,
  Button,
  HStack,
  VStack,
  StackDivider,
  Icon,
  Flex,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Spinner,
  Image,
} from '@chakra-ui/react';
import { CheckCircleIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Link as NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  IconHome,
  IconPortfolio,
  IconStaking,
  IconEject,
  IconBounties,
  IconSockSwap,
} from '../../utils/icons';
import { useWallet } from '@txnlab/use-wallet';
import {
  getAccountTokens,
  getTokenPrice,
  getAlgoBalance,
} from '../../utils/algorand';
import {
  ALGO_TOKEN_DECIMALS,
  SOCKS_TOKEN,
  SOCKS_TOKEN_DECIMALS,
  ALGO_TOKEN,
  GALGO_TOKEN,
  GALGO_TOKEN_DECIMALS,
  COOP_TOKEN,
  COOP_TOKEN_DECIMALS,
} from '../../config/constants';
import IconGalgo from '../../assets/icons/icon-galgo.png';
import IconShCircle from '../../assets/icons/icon-sockhodler.png';
import IconAlgo from '../../assets/icons/icon-algo.png';
import IconCoop from '../../assets/icons/icon-coop.png';
import { userContext } from '../../providers/UserProvider';
import { useQueryParams } from '../../hooks/useQueryParams';

const WalletDetails = modalStep => {
  const { activeAccount, isReady, providers } = useWallet();
  const { onClose } = useDisclosure();
  const { setIsAuthenticated } = useContext(userContext);
  const queryParams = useQueryParams();

  const navigate = useNavigate();
  const location = useLocation();

  // Set initial state for tokenItems
  let initialState = useMemo(() => ([
    {
      symbol: 'ALGO',
      asaID: ALGO_TOKEN,
      label: 'Algorand',
      icon: IconAlgo,
      verified: CheckCircleIcon,
      amount: 0.0,
      priceUSD: 0.0,
      path: 'https://www.coingecko.com/en/coins/algorand',
      decimals: ALGO_TOKEN_DECIMALS,
    },
    {
      symbol: 'SOCKS',
      asaID: SOCKS_TOKEN,
      label: 'SockHodler',
      icon: IconShCircle,
      verified: CheckCircleIcon,
      amount: 0.0,
      priceUSD: 0.0,
      path: 'https://vestige.fi/asset/452047208',
      decimals: SOCKS_TOKEN_DECIMALS,
    },
    {
      symbol: 'gALGO',
      asaID: GALGO_TOKEN,
      label: 'Folks Gov. Token',
      icon: IconGalgo,
      verified: CheckCircleIcon,
      amount: 0.0,
      priceUSD: 0.0,
      path: 'https://vestige.fi/asset/793124631',
      decimals: GALGO_TOKEN_DECIMALS,
    },
    {
      symbol: 'COOP',
      asaID: COOP_TOKEN,
      label: 'COOP COIN',
      icon: IconCoop,
      verified: CheckCircleIcon,
      amount: 0.0,
      priceUSD: 0.0,
      path: `https://vestige.fi/asset/${COOP_TOKEN}`,
      decimals: COOP_TOKEN_DECIMALS,
    },
  ]), []);

  const [tokenItems, setTokenItems] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleDisconnectActiveProvider = async () => {
    const activeProvider = providers?.find(provider => provider.isActive);
    if (activeProvider) {
      await activeProvider.disconnect();
      setIsAuthenticated(false);

      navigate(location.pathname);
    }
  };

  useEffect(() => {
    if (activeAccount && isReady) {
      const updateTokenAmount = async () => {
        setLoading(true);
        const updatedItems = await Promise.all(
          tokenItems.map(async item => {
            const balance = await (item.asaID === ALGO_TOKEN
              ? getAlgoBalance(activeAccount.address)
              : getAccountTokens(activeAccount.address, item.asaID));
            const reformattedBalance = parseFloat((balance / Math.pow(10, item.decimals)).toFixed(2));
            const price = await getTokenPrice(item.asaID);
            return {
              ...item,
              amount: reformattedBalance,
              priceUSD: reformattedBalance * price,
            };
          })
        );
        setTokenItems(updatedItems);
        setLoading(false);
      };
      
      updateTokenAmount();
    }
  }, [isReady, activeAccount]);

  // Menu Items
  const navItems = [
    {
      label: 'Home',
      to: '/',
      icon: IconHome,
      external: false,
    },
    {
      label: 'Portfolio',
      to: '/portfolio',
      icon: IconPortfolio,
      external: false,
    },
    {
      label: 'SockSwap',
      to: '/sockswap',
      icon: IconSockSwap,
      external: false,
    },
    {
      label: 'NFT Staking',
      to: 'https://staking.sockhodler.com',
      icon: IconStaking,
      external: true,
    },
    {
      label: 'SockBot Bounties',
      to: 'https://bounties.sockbotnft.com',
      icon: IconBounties,
      external: true,
    },
  ].map(item => ({
    ...item,
    to: item.external
      ? item.to
      : `${item.to}${queryParams.toString() ? '?' : ''}${queryParams.toString()}`,    
    }));

  return (
    <>
      <ModalBody p={0}>
        {/* Token Body */}
        <VStack h="250px" overflowY={'scroll'}>
          {loading && activeAccount ? (
            <Flex justifyContent="center" alignItems="center" height="100%">
              <Spinner />
            </Flex>
          ) : activeAccount ? (
            tokenItems.map(item => (
              <Flex
                key={item.symbol}
                w={'100%'}
                justify={'space-between'}
                align={'space-between'}
                px={'4'}
                py={'3'}
                _hover={{
                  bg: '#1F0B38',
                }}
              >
                <HStack>
                  <Link href={item.path} isExternal={true}>
                    <Image
                      src={item.icon}
                      objectFit={'contain'}
                      boxSize={10}
                      mr={'2'}
                    />
                  </Link>
                  <VStack align={'stretch'}>
                    <HStack>
                      <Link
                        href={item.path}
                        color={'#FFFFFF'}
                        textDecor={'none'}
                        _hover={{
                          textDecoration: 'none',
                        }}
                        isExternal={true}
                      >
                        <Text fontWeight={'700'}>{item.symbol}</Text>
                      </Link>
                      <Icon as={item.verified} boxSize={5} color={'#FC0174'} />
                    </HStack>
                    <Text color={'#764E8F'}>{item.label}</Text>
                  </VStack>
                </HStack>
                <VStack align={'flex-end'}>
                  <Text>
                    {item.amount === null ? <Spinner size="sm" /> : item.amount}
                  </Text>
                  <Text color="#70708A">
                    {item.priceUSD === null ? (
                      <Spinner size="sm" />
                    ) : (
                      `$${Number.parseFloat(item.priceUSD).toFixed(4)}`
                    )}
                  </Text>
                </VStack>
              </Flex>
            ))
          ) : (
            <Flex justifyContent="center" alignItems="center" height="100%">
              <Text color="#FFFFFF">Connect to View Assets</Text>
            </Flex>
          )}
        </VStack>

        {/* Menu Links */}
        <VStack
          align="stretch"
          bg="#2B083D"
          py={2}
          px={4}
          divider={<StackDivider borderColor="#8A2699" />}
          spacing={2}
        >
          {navItems.map(item => (
            <Link
              as={NavLink}
              to={item.to}
              isExternal={item.external ? true : false}
              href={item.external ? item.to : null}
              key={item.label}
              fontSize="16px"
              textDecoration={'none'}
              my={{ sm: '0.5rem', md: '0.5rem', lg: '0.5rem' }}
              color="white"
              _hover={{
                color: '#FC0174',
              }}
              onClick={onClose}
            >
              <HStack>
                <Icon as={item.icon} boxSize={6} mr={'2'} />
                <Text>{item.label}</Text>
                {item.external && <ExternalLinkIcon mx="5px" />}
              </HStack>
            </Link>
          ))}

          {/* Disconnect Wallet */}

          <Link
            onClick={handleDisconnectActiveProvider}
            fontSize="16px"
            textDecoration={'none'}
            my={{ sm: '0.5rem', md: '0.5rem', lg: '0.5rem' }}
            color="white"
            _hover={{
              color: '#FC0174',
            }}
            cursor="pointer"
          >
            <HStack>
              <Icon as={IconEject} boxSize={6} mr="2" />
              <Text>Disconnect Wallet</Text>
            </HStack>
          </Link>
        </VStack>
      </ModalBody>
      <ModalFooter p={0} borderRadius="0 0 15px 15px" justifyContent="center">
        {/* CTA Button */}
        <Flex margin={4} justify="stretch" align="stretch">
          <Button size="md" w="100%" variant="disabled">
            Buy Upgrade Packs
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};

export default WalletDetails;
