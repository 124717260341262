import { Heading } from '@chakra-ui/react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

const CircularProgressBar = ({value = 0}) => {
  const maxValue = 30; // the maximum progress value

  return (
    <CircularProgressbarWithChildren
      value={value}
      maxValue={maxValue}
      styles={{
        // Customize the root svg element
        root: {},
        // Customize the path, i.e. the "completed progress"
        path: {
          // Path color
          stroke: `rgba(252, 1, 116, 1)`,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'round',
          // Customize transition animation
          transition: 'stroke-dashoffset 0.5s ease 0s',
        },
        // Customize the circle behind the path, i.e. the "total progress"
        trail: {
          // Trail color
          stroke: '#501659',
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'round',
        },
        // Customize the text
        text: {
          // Text color
          fill: '#f88',
          // Text size
          fontSize: '16px',
        },
      }}
    >
      <Heading 
        as={'h2'} 
        fontSize={{
          'xs': '40px',
          'sm': '60px',
          'md': '60px',
          'lg': '72px',
          'xl': '72px',
          '2xl': '72px',
        }} 
        mt={'-15px'}
      >
        {value}
      </Heading>

      <Heading 
        as={'h3'} 
        fontSize={{
          'xs': '14px',
          'sm': '20px',
          'md': '22px',
          'lg': '18px',
          'xl': '22px',
          '2xl': '22px',
        }} 
        fontWeight={'normal'}
      >
        {value === 1 ? 'Day' : 'Days'} Scanned
      </Heading>
    </CircularProgressbarWithChildren>
  );
};

export default CircularProgressBar;
