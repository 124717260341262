import { makeOptInAssetTxn, makeGroupedOptInTxn } from './algorand';
import { SOCKS_TOKEN } from '../config/constants';

// Determines if the mission reward is SOCKS Tokens or an NFT
export const getAssetId = (asset) => {
    switch (asset.type) {
      case 'SOCKS Tokens':
        return SOCKS_TOKEN;
      case 'Optional Asset':
        return asset.id;
      default:
        console.warn(`Unknown asset type: ${asset.type}`);
        return null;
    }
  };
  
  export const generateTransactions = (user_wallet_address, nonXPAssets) => {
    
    // Create empty array to hold transactions
    const transactions = [];
  
    // If there are more than 2 non-XP assets, group them into a single transaction
    if (nonXPAssets.length >= 2) {
      const assetIds = nonXPAssets.map(getAssetId).filter(id => id !== null);
      transactions.push(makeGroupedOptInTxn(user_wallet_address, assetIds));
    } else {
      // Otherwise, create a transaction for the non-XP asset
      nonXPAssets.forEach(asset => {
        const assetId = getAssetId(asset);
        if (assetId) {
          transactions.push(makeOptInAssetTxn(user_wallet_address, assetId));
        }
      });
    }
  
    return transactions;
  };
  
  