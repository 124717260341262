import {
  Text,
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Container,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useEffect, useContext, useMemo } from 'react';
import { AuthenticationService } from '../../services/AuthenticationService';
import AuthIcon from '../AuthIcon/AuthIcon';
import { userContext } from '../../providers/UserProvider';
import isAuth from '../../utils/isAuthenticated';
import { useAuthenticationStatus } from '../../hooks/useAuthenticationStatus';
import { useWallet } from '@txnlab/use-wallet';
import WalletConnect from '../WalletConnect/WalletConnect';
// import { UserService } from '../../services/UserService';
// import { RedeemService } from '../../services/RedeemService';
import { updateLastScanDate } from '../../services/UserService';
import { addNFT } from '../../services/NFTService';

const Authenticate = () => {
  const { user, setUser, isAuthenticated, setIsAuthenticated, setScannedNFT } =
    useContext(userContext);
  const { activeAccount, isReady } = useWallet();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Get the payload from the URL
  const getQueryVariable = variable => {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  };
  let queryVar = 'pl';
  const url_payload = getQueryVariable(queryVar);
  const authStatus = useAuthenticationStatus(url_payload);

  // Create a post_data object
const post_data = useMemo(() => ({
  useragent: 'useragent string goes here',
  ip_address: '111.111.111.1111',
  url_payload,
}), [url_payload]);

useEffect(() => {
  async function authenticate() {
    if (!isAuthenticated) {
      const userRes = await AuthenticationService(post_data);
      if (!userRes.error) {
        setUser(userRes);
        setIsAuthenticated(isAuth(userRes.data.scan.auth_stat));

        // If the nft_token_id field exists in the response, set asa_id to that value in the context
        if (userRes.data && userRes.data.tag && userRes.data.tag.nft_token_id) {
          setScannedNFT(userRes.data.tag.nft_token_id);
        }

        if (isAuth(userRes.data.scan.auth_stat) && isReady) {
          // Post user to db
          await updateLastScanDate(
            new Date().toISOString(),
            activeAccount.address
          );

          await addNFT(
            activeAccount.address,
            userRes.data.tag.nft_token_id
          )
        }
      }
    }
  }

  if (post_data.url_payload) {
    authenticate();
  }

  // open modal
  onOpen();
}, [activeAccount, isReady, isAuthenticated, onOpen, post_data, setScannedNFT, setIsAuthenticated, setUser]);


  return (
    <Container>
      <Flex
        w="100%"
        my="16"
        flexDirection="column"
        justify="stretch"
        align="center"
      >
        {!activeAccount && isAuthenticated && (
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay
              bg="rgba(27, 8, 51, 0.5)"
              backdropFilter="blur(6px)"
            />
            <ModalContent
              borderRadius={'15px'}
              bg={'#1B0833'}
              border={'2px solid #8A2699'}
            >
              <ModalHeader>Connect a wallet</ModalHeader>
              <ModalBody>
                <WalletConnect />
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        <Flex
          w={'175px'}
          h={'175px'}
          justify="center"
          align="center"
          as={motion.div}
          initial={{ opacity: 0, scale: 0, rotate: 90 }}
          animate={{ opacity: 1, scale: 1, rotate: 0 }}
          transition=".45s ease-out"
        >
          <AuthIcon
            icon={authStatus.icon}
            w={{ sm: '125px', md: '150px', lg: '175px' }}
          />
        </Flex>

        <Heading mt={10} mb={2} align={'center'}>
          {authStatus.type}
        </Heading>

        <Text mb={10} align={'center'}>
          {authStatus.message}
        </Text>
      </Flex>
    </Container>
  );
};

export default Authenticate;
