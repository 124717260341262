import { useState, useEffect } from 'react';
import axios from 'axios';

export const useSocksPrice = () => {
  const [socksPrice, setSocksPrice] = useState(null);
  const [priceChange, setPriceChange] = useState(0);

  useEffect(() => {
    const getSocksPrice = async () => {
      try {
        const response = await axios.get('https://free-api.vestige.fi/asset/452047208/price');
        let data = response.data;

        if (socksPrice) {
          const previousPrice = socksPrice.USD;
          const currentPrice = data.USD;
          const percentageChange = ((currentPrice - previousPrice) / previousPrice) * 100;
          setPriceChange(percentageChange);
        }

        setSocksPrice(data);
      } catch (error) {
        console.error(error);
      }
    };

    getSocksPrice();
    const intervalId = setInterval(getSocksPrice, 1000 * 60 * 15);

    // Return a cleanup function
    return () => clearInterval(intervalId);
  }, []);

  return { socksPrice, priceChange };
};
