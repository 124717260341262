import { algodClient } from './algorand';
import { indexerClient } from './algorand';
import { decodeAddress } from 'algosdk';
import * as mfsha2 from 'multiformats/hashes/sha2';
import * as digest from 'multiformats/hashes/digest';
import { CID } from 'multiformats/cid';
import { IPFS_GATEWAY } from '../config/constants';

const replaceIpfsPrefix = (url, prefix) => {
  if (url.startsWith('ipfs://')) {
    return prefix + url.slice(7);
  }
  return url;
};

export const checkGatewaySpec = (url, reserveAddr) => {
  // console.log('checkNFTSpec', url, reserveAddr);
  let chunks = url.split('://');

  if (chunks[0] === 'template-ipfs' && chunks[1].startsWith('{ipfscid:')) {
    chunks[0] = 'ipfs';
    const cidComponents = chunks[1].split(':');
    if (cidComponents.length !== 5) {
      console.log('Unknown ipfscid format');
      return url;
    }
    const [, cidVersion, cidCodec, asaField, cidHash] = cidComponents;

    if (cidHash.split('}')[0] !== 'sha2-256') {
      console.log('Unsupported Hash', cidHash);
      return url;
    }
    if (cidCodec !== 'raw' && cidCodec !== 'dag-pb') {
      console.log('Unsupported Codec', cidCodec);
      return url;
    }
    if (asaField !== 'reserve') {
      console.log('Unsupported ASA field:', asaField);
      return url;
    }

    let cidCodecCode;
    if (cidCodec === 'raw') {
      cidCodecCode = 0x55;
    } else if (cidCodec === 'dag-pb') {
      cidCodecCode = 0x70;
    }

    // get 32 bytes Uint8Array reserve address - treating it as 32-byte sha2-256 hash
    const addr = decodeAddress(reserveAddr);
    const mhdigest = digest.create(mfsha2.sha256.code, addr.publicKey);

    const cid = CID.create(parseInt(cidVersion), cidCodecCode, mhdigest);
    // console.log('switching to id:', cid.toString());
    chunks[1] = cid.toString() + '/' + chunks[1].split('/').slice(1).join('/');
    // console.log('redirecting to ipfs:', chunks[1]);
  }

  // No protocol specified, give up
  if (chunks.length < 2) return url;

  //Switch on the protocol
  switch (chunks[0]) {
    case 'ipfs': //Its ipfs, use the configured gateway
      return 'ipfs://' + chunks[1];
    case 'https': //Its already http, just return it
      return url;
    default:
      console.log('Unsupported protocol:', chunks[0]);
      return url;
  }
};

export const checkARCSpec = async asset => {
  const query = await indexerClient
    .lookupAssetTransactions(asset)
    .txType('acfg')
    .do();

  if (query.transactions.length === 0) {
    throw new Error('No asset config transactions found');
  }

  const sortedTransactions = query.transactions.sort(
    (a, b) => b.round - a.round
  );


  const url =
    sortedTransactions[0]['asset-config-transaction']['params']['url'];
  
  
    let note = null;

try {
  note = sortedTransactions[0]['note']
    ? JSON.parse(
        Buffer.from(sortedTransactions[0]['note'], 'base64').toString('utf-8')
      )
    : null;
} catch (error) {
}

  const isARC19 = url && url.includes('template-ipfs://');
  const isARC69 = note && note['standard'] === 'arc69';

  if (isARC19) {
    const IpfsUrl = checkGatewaySpec(
      url,
      sortedTransactions[0]['asset-config-transaction']['params']['reserve']
    );

    // Returns the URL to the JSON Metadata

    let httpsUrl = replaceIpfsPrefix(IpfsUrl, IPFS_GATEWAY);

    if (isARC69) {

      return {
        type: 'ARC19/ARC69',
        url: httpsUrl,
      };
    } else {
      return {
        type: 'ARC19',
        url: httpsUrl,
      };
    }
  }

  if (isARC69) {
    const IpfsUrl = replaceIpfsPrefix(url, IPFS_GATEWAY);
    return {
      type: 'ARC69',
      url: IpfsUrl,
    };
  }

  // If neither ARC19 nor ARC69
  if (!isARC19 && !isARC69) {
    const IpfsUrl = replaceIpfsPrefix(url, IPFS_GATEWAY);
    return {
      type: 'ARC3',
      url: IpfsUrl,
    };
  }
};

export const getTraitsARC69 = async asset => {
  // This function returns the ARC69 note field from the asset config txn
  const query = await indexerClient
    .lookupAssetTransactions(asset)
    .txType('acfg')
    .do();

  if (query.transactions.length === 0) {
    throw new Error('No asset config transactions found');
  }

  const sortedTransactions = query.transactions.sort(
    (a, b) => b.round - a.round
  );

  const note = sortedTransactions[0]['note'];
  const decodedNote = Buffer.from(note, 'base64').toString('utf-8');
  const decodedNoteJSON = JSON.parse(decodedNote);
  return decodedNoteJSON;
};
