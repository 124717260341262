import {
  Flex,
  Heading,
  SimpleGrid,
  VStack,
  Box,
  Spinner,
  Container,
  useToast,
  // useDisclosure,
} from '@chakra-ui/react';
import DailyScanRewards from '../DailyScanRewards/dailyScanRewards';
import NFTLevel from '../NFTLevel/NFTLevel';
import TitleHeading from '../TitleHeading/TitleHeading';
import Missions from '../Missions/Missions';
import AchievementBadges from '../AchievementBadges/AchievementBadges';
import { useContext, useState, useEffect } from 'react';
import { userContext } from '../../providers/UserProvider';
import { xpRange } from '../../utils/xpRange';
import { useWallet } from '@txnlab/use-wallet';
import { getUser } from '../../services/UserService';
import { useNFTContent } from '../../hooks/useNFTContent';
import { MASTER_API_URL } from '../../config/constants';
// import Evolution from '../Evolution/Evolution';
// import useEvolution from '../../hooks/useEvolution';
// import StartEvolution from '../StartEvolution/StartEvolution';

const Dashboard = ({ asaName, url, asaId, reserveAddress }) => {
  const {
    claimed,
    setClaimed,
    user,
    xp,
    setXp,
    nftLevel,
    setNFTLevel,
    badges,
    setBadges,
  } = useContext(userContext);
  const [loading, setLoading] = useState(true);
  const [previousLevel, setPreviousLevel] = useState(null);

  const { activeAccount, isReady } = useWallet();
  // const { evolutionUsed, onEvolutionUsed } = useEvolution();
  // const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const imageProps = {
    url: url,
    reserveAddr: reserveAddress,
  };

  const { contentComponent } = useNFTContent(imageProps);

  const updateXp = async newXpValue => {
    let newXp = xp + newXpValue;
    setXp(newXp);

    let newLevel = Array.from(xpRange.values()).find(
      range => newXp >= range.minXp && newXp < range.maxXp
    );

    if (newLevel) {
      if (previousLevel !== null && newLevel.level !== previousLevel.level) {
        toast({
          title: 'Nice job!',
          description: 'You leveled up.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      setPreviousLevel(newLevel);
      setNFTLevel(newLevel);
    }

    return newLevel;
  };

  useEffect(() => {
    if (isReady && activeAccount && user.data.tag.nft_token_id) {
      async function fetchData() {
        try {
          const { data: userData } = await getUser(activeAccount.address);
          const response = await fetch(
            `${MASTER_API_URL}/sockvault/getnfts/${user.data.tag.nft_token_id}`
          );

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const nftData = await response.json();

          if (nftData && 'xp' in nftData) {
            let newLevel = Array.from(xpRange.values()).find(
              range => nftData.xp >= range.minXp && nftData.xp < range.maxXp
            );

            setXp(nftData.xp);

            if (newLevel) {
              setNFTLevel(newLevel);
            } else {
              console.error(
                'The level property is not present in the nftData object'
              );
            }

            if (userData && 'badges_unlocked' in userData) {
              setBadges(userData.badges_unlocked);
            } else {
              console.error('userData or its properties are undefined');
            }

            setLoading(false);
          } else {
            console.error('nftData or its properties are undefined');
          }
        } catch (error) {
          console.error('Error fetching user and NFT data:', error.message);
        }
      }

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isReady,
    claimed,
    activeAccount,
    setXp,
    setNFTLevel,
    user.data.tag.nft_token_id,
  ]);

  // const handleEvolutionUsed = (option) => {
  //   onEvolutionUsed(option);
  // };

  function randomXp(min, max) {
    if (nftLevel && nftLevel.multiplier) {
      return Math.floor(
        (Math.random() * (max - min) + min) * nftLevel.multiplier
      );
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  }

  return (
    <Container
      maxW={{
        base: '100%',
        sm: 'container.sm',
        md: 'container.md',
        lg: 'container.lg',
        xl: 'container.xl',
        '2xl': '100%',
      }}
      centerContent
      p={{ base: 4, sm: 6 }}
    >
      <TitleHeading mainTitle="Welcome Back" />

      {/* Daily Scan Rewards and NFT */}
      <SimpleGrid
        align={'center'}
        columns={{ sm: 1, md: 1, lg: 2, xl: 2, '2xl': 2 }}
        spacing={{
          sm: '10px',
          md: '30px',
          lg: '60px',
          xl: '60px',
          '2xl': '120px',
        }}
        mt="60px"
        width={'100%'}
      >
        <Flex
          flexDir={'column'}
          justify={'center'}
          align={'center'}
        >
          {loading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="lg"
            />
          ) : (
            <DailyScanRewards
              xpValue={randomXp(50, 100)}
              level={nftLevel.level}
              onClaim={() => setClaimed(true)}
              onUpdateXp={updateXp}
            />
          )}
        </Flex>

        <Flex flexDir={'column'} justify={'center'} align={'center'}>
          {/* NFT Image */}
          <Box
            borderRadius="2xl"
            maxW="350px"
            boxShadow="dark-lg"
            border={'1px #501659 solid'}
            overflow={'hidden'}
            fallback={
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            }
          >
            {loading ? (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            ) : (
              contentComponent
            )}
          </Box>

          <Heading
            as="h2"
            color="white"
            fontSize="24px"
            mt="15"
            textAlign={'center'}
          >
            {asaName}
          </Heading>

          <Heading
            as="h3"
            color="white"
            fontSize="16px"
            mt="2"
            mb="2"
            fontWeight={'normal'}
            textAlign="center"
          >
            ASA ID: {asaId}
          </Heading>

          {loading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="lg"
            />
          ) : (
            <NFTLevel nftLevel={nftLevel} xp={xp} claimed={claimed} />
          )}

          {/* {!evolutionUsed && nftLevel.level >= 10 && (
        <>
          <button onClick={onOpen}>Use Evolution</button>
          <Evolution
            isOpen={isOpen}
            onClose={onClose}
            onEvolutionUsed={handleEvolutionUsed}
          />
        </>
      )}        */}
        </Flex>
      </SimpleGrid>

      {/* Achievements and Multipliers */}
      <VStack
        align={'stretch'}
        pt={{ base: 8, lg: 16 }}
        mt={{ base: 4, lg: 8 }}
        width={'100%'}
      >
        <Box>
          <Heading as="h2" fontSize={'2xl'}>
            Achievements
          </Heading>
          <hr />
        </Box>
        <AchievementBadges badges={badges} />
      </VStack>

      {/* Mission Control */}
      <VStack
        align={'stretch'}
        pt={{ base: 8, lg: 16 }}
        mt={{ base: 8, lg: 16 }}
        gap={6}
        width={'100%'}
      >
        <Box>
          <Heading as="h2" fontSize={'2xl'}>
            Mission Control
          </Heading>
          <hr />
        </Box>
        <Missions onUpdateXp={updateXp} />
      </VStack>
    </Container>
  );
};

export default Dashboard;
