import React from 'react';
import { Flex } from '@chakra-ui/react';
const Tabs = ({ children }) => {
  return (
    <Flex
      h="75"
      py="2"
      px="4"
      align="center"
      justify="center"
      alignSelf={'center'}
      borderRadius="full"
      border={'1px #501659 solid'}
      bgGradient="linear(to-r, purple.300, purple.200)"
      gap={4}
      flexDir="row"
    >
      {children}
    </Flex>
  );
};

export default Tabs;
