import { Flex, Heading } from '@chakra-ui/react';

const TitleHeading = ({ mainTitle, subTitle }) => {
  return (
    <Flex flexDirection="column" mt="10" justify={'center'}>
      <Heading
        as="h1"
        fontSize={{ 
          xs: '4xl', 
          sm: '5xl', 
          md: '5xl', 
          lg: '6xl', 
          xl: '6xl', 
          '2xl': '6xl' 
        }}
        fontWeight="black"
        letterSpacing="wide"
        textTransform="uppercase"
        mb="2"
        textAlign={'center'}
        wordwrap={'break-word'}
      >
        {mainTitle}
      </Heading>

      <Heading
        as="h2"
        fontSize="xl"
        color="pink.500"
        fontWeight="normal"
        textAlign={'center'}
      >
        {subTitle}
      </Heading>
    </Flex>
  );
};

export default TitleHeading;
