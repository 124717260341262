const colors = {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    purple: {
      100: '#642373',
      200: '#2B083D',
      300: '#030415',
      350: 'rgba(3, 4, 21, .75)',
    },
    pink: {
      50: '#FFF5F7',
      100: '#FED7E2',
      200: '#FBB6CE',
      300: '#F687B3',
      400: '#ED64A6',
      500: '#D53F8C',
      600: '#B83280',
      700: '#97266D',
      800: '#702459',
      900: '#521B41',
    },
    blue: {
      50: '#EBF8FF',
      100: '#BEE3F8',
      200: '#90CDF4',
      300: '#63B3ED',
      400: '#4299E1',
      500: '#3182CE',
      600: '#2B6CB0',
      700: '#2C5282',
      800: '#2A4365',
      900: '#1A365D',
    },
    cyan: {
      50: "#E5FBFF",
      100: "#B8F3FF",
      200: "#8AECFF",
      300: "#5CE5FF",
      400: "#2EDDFF",
      500: "#00D6FF",
      600: "#00ABCC",
      700: "#008099",
      800: "#005566",
      900: "#002B33"
    },
    "green": {
      50: "#ECF8F2",
      100: "#CAECDA",
      200: "#A8E0C3",
      300: "#86D4AB",
      400: "#65C894",
      500: "#43BC7C",
      600: "#359763",
      700: "#28714A",
      800: "#1B4B32",
      900: "#0D2619"
    },
    gray: {
      50: '#f7fafc',
      900: '#171923',
    },
  }

  export default colors;