import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import NFTCard from '../NFTCard/NFTCard';
import { getOwnedAssets } from '../../utils/algorand';
import {
  SOCKBOT_COLLECTION_ADDRESS,
  SOCKBOT_V2_COLLECTION_ADDRESS,
  GENESIS_COLLECTION_ADDRESS,
} from '../../config/constants';

const NFTList = ({ creatorAddress, userAddress }) => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOwnedNfts = useCallback(async () => {
    const responseV1 = await getOwnedAssets(SOCKBOT_COLLECTION_ADDRESS, userAddress);
    const responseV2 = await getOwnedAssets(SOCKBOT_V2_COLLECTION_ADDRESS, userAddress);
    const responseGenesis = await getOwnedAssets(GENESIS_COLLECTION_ADDRESS, userAddress);

    const combinedResponses = [...responseV1, ...responseV2, ...responseGenesis];
    const ownedNftsArray = Object.values(combinedResponses);

    setNfts(ownedNftsArray);
    setLoading(false);
  }, [userAddress]);

  useEffect(() => {
    fetchOwnedNfts();
  }, [fetchOwnedNfts]);

  return (
    <Flex mt={8} wrap={'wrap'} align={'stretch'} justify={'space-evenly'}>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        nfts.map((nft) => (
          <NFTCard
            key={nft['index']}
            index={nft['index']}
            name={nft['params']['name']}
            url={nft['params']['url']}
            total={nft['params']['total']}
            reserveAddr={nft['params']['reserve']}
          />
        ))
      )}
    </Flex>
  );
};

export default NFTList;
