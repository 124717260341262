import { defineStyleConfig } from '@chakra-ui/react'

const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'normal',
    textTransform: 'none',
    borderRadius: 'sm', // <-- border radius is same for all variants and sizes
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4, 
      py: 3, 
    },
    md: {
      fontSize: 'md',
      px: 6, 
      py: 4, 
    },
    lg: {
      fontSize: 'lg',
      px: 12, 
      py: 8, 
    },
    xl: {
      fontSize: 'xl',
      px: 12, 
      py: 8, 
    },
    custom: {
      fontSize: 'xl',
      p: 1,
      h: 50,
      w: 50,
    }
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: '1px solid',
      borderColor: 'white',
      color: 'white',
      borderRadius: 'full',
    },
    outlineNotRounded: {
      border: '1px solid',
      borderColor: 'white',
      color: 'white',
      borderRadius: 'md',
    },
    solidPurple: {
      bg: 'purple.500',
      bgGradient: 'linear(to-r, purple.200, purple.100)',
      color: 'white',
      borderRadius: 'xl',
      border: '1px solid',
      borderColor: 'purple.100',
      _hover: {
        bg: 'purple.600',
        bgGradient: 'linear(to-r, purple.300, purple.200)',
      }
    },
    solidBlue: {
      bg: 'blue.500',
      color: 'white',
    },
    disabled: {
      cursor: 'not-allowed',
      boxShadow: 'none',
      backgroundColor: '#70708A',
      color: '#999',
      borderRadius: 'xl',
      border: '1px transparent',
    },
    clean: {
      bg: 'purple.500',
      bgGradient: 'linear(to-r, purple.200, purple.100)',
      color: 'white',
      borderRadius: 'full',
      border: '1px solid',
      borderColor:"#B810FF",
      _hover: {
        bg: 'purple.600',
        bgGradient: 'linear(to-r, purple.300, purple.200)',
      }
    },
    naked: {
      bg: 'none',
      p: '0px',
      height: 'auto',
      borderRadius: 'full',
      
      display: 'block'
      
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'solidPurple',
  },
})

export default Button;