import {
  Flex,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useWallet } from '@txnlab/use-wallet';
import React, { useEffect, useState } from 'react';
import NFTList from '../../components/NFTList/NFTList';
import TitleHeading from '../../components/TitleHeading/TitleHeading';
import {
  // ALGO_TOKEN,
  SOCKS_TOKEN,
  SOCKS_TOKEN_DECIMALS,
  ALGO_TOKEN_DECIMALS,
} from '../../config/constants';
import {
  getAccountTokens,
  getAlgoBalance,
  reformatDecimals,
} from '../../utils/algorand';

const Portfolio = () => {
  const { activeAccount, isReady } = useWallet();
  const [asset, setAsset] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const socksTokensHoldings = async () => {
      if (!activeAccount) return;
      const holdingSocks = await getAccountTokens(
        activeAccount.address,
        SOCKS_TOKEN
      );

      const holdingAlgo = await getAlgoBalance(activeAccount.address);

      setAsset({
        socks: reformatDecimals(holdingSocks, SOCKS_TOKEN_DECIMALS),
        algo: reformatDecimals(holdingAlgo, ALGO_TOKEN_DECIMALS),
      });
      setLoading(false);
    };
    socksTokensHoldings();
  }, [activeAccount]);

  return (
    <>
      <TitleHeading
        mainTitle="Portfolio"
        subTitle="View your NFTs and SockBot Components"
      />

      <Flex mt="20" align="center" justifyContent="center" flexDir={'column'}>
        {activeAccount && isReady && (
          <Stack direction={'row'} gap={10}>
            <Stat>
              <StatLabel>SOCKS</StatLabel>
              <StatNumber>
                {isLoading ? (
                  <Flex align="center" justify="center" h="100%">
                    <Spinner />
                  </Flex>
                ) : asset.socks !== null || asset.socks !== undefined ? (
                  asset.socks
                ) : (
                  <Flex align="center" justify="center" h="100%">
                    <Spinner />
                  </Flex>
                )}
              </StatNumber>
            </Stat>

            <Stat>
              <StatLabel>ALGO</StatLabel>
              <StatNumber>
                {isLoading ? (
                  <Flex align="center" justify="center" h="100%">
                    <Spinner />
                  </Flex>
                ) : asset.algo !== null || asset.algo !== undefined ? (
                  asset.algo
                ) : (
                  <Flex align="center" justify="center" h="100%">
                    <Spinner />
                  </Flex>
                )}
              </StatNumber>
            </Stat>
          </Stack>
        )}

        <Heading as={'h2'} my={10}>
          My NFTs
        </Heading>

        {activeAccount && isReady ? (
          <NFTList
            creatorAddress="SC5KS4RNGJXC5KMAUXOLZONS22I6AV2YW2WIF7AJX63AJKNPVSBKMYPSEM"
            userAddress={activeAccount.address}
          />
        ) : (
          <Flex align={'center'} justify={'center'} flexDir={'column'}>
            <Flex align="center" justify="center" h="100%">
              <Text fontSize="xl" fontWeight="bold">
                Connect your wallet to view your NFTs
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default Portfolio;
